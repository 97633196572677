import {
  ArrowRightOnRectangleIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  CalculatorIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";

import clsx from "clsx";
import { Link } from "react-router-dom";
import { MenuStore } from "../../store/MenuStore";

import MessageImg from "./../../assets/img/icon/message-icon.svg";
import logoParis from "./../../assets/img/exnext-logo/paris.png";
import logoMedical from "./../../assets/img/exnext-logo/medical.png";
import logoService from "./../../assets/img/exnext-logo/service.png";
import favParis from "./../../assets/img/favicon/parisfavicon.png";
import favMedical from "./../../assets/img/favicon/medicalfavicon.jpg";
import favService from "./../../assets/img/favicon/medicalfavicon.jpg";
import { useAppContext } from "../../context";
import { SwitchComponent } from "../../components/Utils/Index";
import { toast } from "react-toastify";
import { userService } from "../../services/user/UserService";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const SideBarMenu = ({
  menuData,
  space = "admin",
  sidebarTextShow,
  setSidebarTextShow,
}) => {
  const logOut = () => {
    localStorage.removeItem("accessToken");
    window.location.href = "/";
  };

  const { isAuth, setIsAuth } = useAppContext();
  const [isLoading, setisLoading] = useState(false)
  const [contentData, setContentData] = useState({});

  const lofoLink =
    space === "paris"
      ? logoParis
      : space === "medical"
        ? logoMedical
        : space === "service"
          ? logoService
          : logoParis;
  const lofoLink2 =
    space === "paris"
      ? favParis
      : space === "medical"
        ? favMedical
        : space === "service"
          ? favService
          : favParis;

  const updateUserInfo = async (value) => {
    try {
      setisLoading(true)
      const toastsId = toast.loading("Modification en cours ...", {
        position: "bottom-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      userService.updateUser(
        {
          tracking_mail: value
        }
      ).then(
        (response) => {
          console.log(response)
          toast.update(toastsId, {
            render: "Modifcation effecutée",
            type: "success",
            isLoading: false,
            autoClose: 1000,
            position: "bottom-center",
          });
          setisLoading(false)
          // const payload = { ...user, ...response.data.data }
          setIsAuth({ ...isAuth, ...response.data.data })
        },
        (error) => {
          console.log(error)
          toast.update(toastsId, {
            render: "Modification non effecuté",
            type: "error",
            isLoading: false,
            autoClose: 1000,
            position: "bottom-center",
          });

          setisLoading(false)
        }
      )
    } catch (error) {
      console.log(error)
      toast.error("Une erreur lors de la soumission", {
        position: "bottom-center",
        theme: "light",
        autoClose: 1000,
      });
      setisLoading(false)
    }
  }

  useEffect(() => {
    const fecthInformaton = async () => {
      try {
        userService.LastInformation().then(
          (response) => {

            setContentData({ ...response.data.data });
          },
          (error) => {

            console.log(error);
          }
        );
      } catch (error) {

        console.log("error", error);
      }
    };

    fecthInformaton();
  }, [])


  return (
    <div className="flex flex-col px-6 pb-4 overflow-y-auto bg-white rounded-lg shadow-xl grow">
      <div className="flex items-center justify-between h-16 my-3 shrink-0">
        <Link
          to="/"
          className="flex items-center text-2xl font-black cursor-pointer whitespace-nowrap"
        >
          {sidebarTextShow && (
            <img
              src={lofoLink}
              alt={"logo"}
              className={"h-20 w-52 image-cover "}
            />
          )}

          {!sidebarTextShow && (
            <img
              src={lofoLink2}
              height={100}
              width={200}
              alt={"logo"}
              className={"h-8 w-8 image-cover "}
            />
          )}

          {/* {sidebarTextShow && <>EXCELENCHY</>} */}
        </Link>

        <div className="relative items-center justify-end hidden rounded-l-full md:flex">
          {!sidebarTextShow && (
            <ChevronRightIcon
              className={clsx(
                {
                  "bg-paris": space === "paris",
                  "bg-medical": space === "medical",
                  "bg-service": space === "service",
                  "bg-admin": space === "admin",
                },
                "absolute z-50 w-6 h-6 -mr-2 text-white rounded-l-full cursor-pointer -right-5"
              )}
              onClick={() => setSidebarTextShow(true)}
            />
          )}

          {sidebarTextShow && (
            <ChevronLeftIcon
              className={clsx(
                {
                  "bg-paris": space === "paris",
                  "bg-medical": space === "medical",
                  "bg-service": space === "service",
                  "bg-admin": space === "admin",
                },
                "absolute z-50 w-6 h-6 -mr-2 text-white rounded-l-full cursor-pointer -right-5"
              )}
              onClick={() => setSidebarTextShow(false)}
            />
          )}
        </div>
      </div>
      <nav className="flex flex-col flex-1">
        <ul role="list" className="flex flex-col flex-1 gap-y-7">
          <li>
            <ul role="list" className="-mx-2 space-y-1">
              {menuData.map((item) => (
                <MenuItem
                  item={item}
                  key={item.tague}
                  sidebarTextShow={sidebarTextShow}
                  space={space}
                />
              ))}
            </ul>

            {isAuth?.userRole === "client" && parseInt(isAuth?.calculatrice) === 1 && (
              <>
                <li
                  className={
                    "calculatrice" === window.location.pathname
                      ? "active"
                      : window.location.pathname.includes("calculatrice")
                        ? "activeflex items-center"
                        : "flex items-center pl-2 my-2"
                  }
                >
                  <Link
                    to={`/client/${isAuth?.spaceName}/dash/calculatrice`}
                    className={"flex items-center text-base text-gray-900 cursor-pointer"}
                  >
                    {/* <i className={menuItem.icon}></i>{" "} */}
                    <CalculatorIcon className={clsx({ "text-paris": isAuth?.spaceName === "paris", "text-medical": isAuth?.spaceName === "medical", "text-service": isAuth?.spaceName === "service" }, "w-4 h-4")} />

                    {sidebarTextShow && <span className="ml-2 text-base text-gray-900 cursor-pointer"> Calculatrice</span>}
                  </Link>
                </li>
              </>
            )}

            {isAuth?.userRole === "client" &&
              (<li>
                <div className="flex items-center justify-between pl-2 my-3">
                  <label
                    htmlFor="tracking_mail"
                    className="flex items-center gap-2 text-base font-semibold text-gray-900 cursor-pointer"
                  >
                    <EnvelopeIcon className={clsx({ "text-paris": isAuth?.spaceName === "paris", "text-medical": isAuth?.spaceName === "medical", "text-service": isAuth?.spaceName === "service" }, "w-4 h-4")} />
                    {sidebarTextShow && <span className="ml-2 text-base text-gray-900 cursor-pointer"> Mail de  Tracking</span>}
                  </label>
                  <SwitchComponent
                    id="tracking_mail"
                    label="tracking_mail"
                    checked={isAuth?.tracking_mail === 1}
                    onChange={(checked) => {
                      updateUserInfo(checked ? 1 : 0)
                    }}
                    checkedColor="#098721"
                  />
                </div>
              </li>)}
          </li>

          {sidebarTextShow && <>
            {isAuth?.userRole === "client" && contentData.active_baniere === 1 && (
              <div
                className={clsx({ "bg-paris": isAuth?.spaceName === "paris", "bg-medical": isAuth?.spaceName === "medical", "bg-service": isAuth?.spaceName === "service" }, "w-[220px] mx-auto py-4 px-2 text-center rounded-xl mt-10 shadow text-white")}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignContent: "center",
                  }}
                >
                  {contentData?.with_image === 1 && contentData?.image_url && contentData?.image_url !== "" && (
                    <img
                      src={
                        process.env.REACT_APP_BACKEND_HOST +
                        "/" +
                        contentData?.image_url
                      }
                      alt={contentData?.image_url}
                      className={"h-50 rounded-lg mb-3"}
                      height={100}
                      width={100}
                    />
                  )}
                </div>

                <h4 className={"text-base text-center font-semibold font-normal mb-2"}>
                  {contentData?.libele}
                </h4>

                <p className={"text-sm font-light text-center mb-3"}
                  dangerouslySetInnerHTML={{ __html: contentData?.contenus?.replace(/\n/g, '<br>') }}>
                </p>
                <a
                  href={"mailto:operations@exnetservices.com"}
                  className={clsx({ "bg-paris": isAuth?.spaceName === "paris", "bg-medical": isAuth?.spaceName === "medical", "bg-service": isAuth?.spaceName === "service" }, "px-3 py-1 text-sm font-normal text-center text-white border border-white rounded-lg")}
                >
                  Nous contacter
                </a>
              </div>
            )}
          </>}



          <li className={clsx({ "bg-paris": isAuth?.spaceName === "paris", "bg-medical": isAuth?.spaceName === "medical", "bg-service": isAuth?.spaceName === "service" }, "mt-auto text-white rounded-md pl-2")} >
            <Link
              onClick={logOut}
              to="#"
              className="flex p-2 -mx-2 text-sm font-semibold leading-6 text-white rounded-md group gap-x-3 "
            >
              <ArrowRightOnRectangleIcon
                className="w-6 h-6 text-white shrink-0 "
                aria-hidden="true"
              />
              {sidebarTextShow && "Déconnexion"}
            </Link>
          </li>
        </ul>


      </nav>
    </div >
  );
};

const MenuItem = ({ item, sidebarTextShow, space }) => {
  const [OpenChildLink, setOpenChildLink] = useState(false);
  const { tague } = MenuStore((state) => state.activeMenu);
  const setActiveMenu = MenuStore((state) => state.setActiveMenu);

  useEffect(() => {
    if (
      item?.liens &&
      Array.isArray(item?.liens) &&
      item?.liens.find(
        (childItem) =>
          tague.indexOf(item.tague) > -1 || childItem.tague === tague
      )
    ) {
      setOpenChildLink(true);
    } else {
      setOpenChildLink(false);
    }
  }, [item, tague]);

  return (
    <>
      {item?.isTitle && sidebarTextShow ? (
        <>
          <li
            className={clsx(
              {
                "bg-paris": space === "paris",
                "bg-medical": space === "medical",
                "bg-service": space === "service",
                "text-gray-300 bg-admin ": space === "admin",
              },
              "text-center text-lg font-semibold leading-6 py-2 rounded-md text-white"
            )}
          >
            {item?.libelle}
          </li>
        </>
      ) : (
        <>
          {" "}
          {item?.isMultiple ? (
            <li key={item?.libelle} className={"cursor-pointer"}>
              <span
                onClick={(e) => setActiveMenu(item)}
                className={clsx(
                  {
                    "text-white bg-black":
                      space && tague?.indexOf(item?.tague) > -1,
                    "hover:text-white hover:bg-black": space,
                  },
                  " group flex gap-x-3 rounded-md py-2 text-lg font-semibold leading-6 px-2"
                )}
              >
                {item?.icon}
                {sidebarTextShow && (
                  <div
                    className={
                      "hidden md:flex items-center justify-between w-full text-lg"
                    }
                  >
                    {/* {item.libelle} */}
                    {sidebarTextShow && (
                      <span
                        className={"hidden md:inline text-lg font-semibold"}
                      >
                        {" "}
                        {item?.libelle}
                      </span>
                    )}
                    <span className={" inline md:hidden text-lg font-semibold"}>
                      {" "}
                      {item?.libelle}
                    </span>
                    {OpenChildLink ? (
                      <ChevronUpIcon
                        className={clsx(
                          {
                            "text-white bg-black":
                              space && OpenChildLink,
                            // 'bg-primary text-white': OpenChildLink,
                            "text-black ": !OpenChildLink,
                          },
                          "h-6 w-6 shrink-0"
                        )}
                        aria-hidden="true"
                      />
                    ) : (
                      <ChevronDownIcon
                        className={clsx(
                          {
                            "text-white bg-black":
                              space && OpenChildLink,
                            "text-black group-hover:bg-primary group-hover:text-white ":
                              !OpenChildLink,
                          },
                          "h-6 w-6 shrink-0"
                        )}
                        aria-hidden="true"
                      />
                    )}
                  </div>
                )}
                <div
                  className={
                    "md:hidden flex items-center justify-between w-full"
                  }
                >
                  {/* {item?.libelle} */}
                  {sidebarTextShow && (
                    <span className={"hidden md:inline"}> {item?.libelle}</span>
                  )}
                  <span className={" inline md:hidden"}> {item?.libelle}</span>

                  {OpenChildLink ? (
                    <ChevronUpIcon
                      className={clsx(
                        {
                          "text-white bg-black":
                            space && OpenChildLink,
                          "text-black group-hover:text-white group-hover:bg-primary":
                            !OpenChildLink,
                        },
                        "h-6 w-6 shrink-0"
                      )}
                      aria-hidden="true"
                    />
                  ) : (
                    <ChevronDownIcon
                      className={clsx(
                        {
                          "text-white bg-black":
                            space && OpenChildLink,
                          "text-black group-hover:bg-primary group-hover:text-white":
                            !OpenChildLink,
                        },
                        "h-6 w-6 shrink-0"
                      )}
                      aria-hidden="true"
                    />
                  )}
                </div>
              </span>
              {item?.liens && OpenChildLink && sidebarTextShow && (
                <ul role="list" className="ml-8 space-y-2">
                  {item?.liens.map((childItem, position) => (
                    <li
                      key={childItem?.libelle}
                      onClick={(e) => setActiveMenu(childItem)}
                    >
                      <Link
                        to={childItem?.lien}
                        className={clsx(
                          {
                            "mt-2": position === 0,
                            "text-white bg-black":
                              space === "paris" && childItem?.tague === tague,
                            "text-white bg-medical":
                              space === "medical" && childItem?.tague === tague,
                            "text-white bg-service":
                              space === "service" && childItem?.tague === tague,
                            "text-white bg-admin":
                              space === "admin" && childItem?.tague === tague,

                            "hover:text-white hover:bg-black":
                              space === "paris",
                            "hover:text-white hover:bg-medical":
                              space === "medical",
                            "hover:text-white hover:bg-service":
                              space === "service",
                            "hover:text-white hover:bg-admin":
                              space === "admin",
                          },
                          "group flex gap-x-3 rounded-md p-2 text-lg font-normal leading-6 "
                        )}
                      >
                        <span
                          className={clsx({
                            "text-white bg-black":
                              space === "paris" &&
                              childItem?.tague === tague,
                            "text-white bg-medical":
                              space === "medical" &&
                              childItem?.tague === tague,
                            "text-white bg-service":
                              space === "service" &&
                              childItem?.tague === tague,
                            "text-white bg-admin":
                              space === "admin" &&
                              childItem?.tague === tague,
                          })}
                        >
                          {childItem?.libelle}
                        </span>
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ) : (
            <li
              key={item?.libelle}
              onClick={(e) => {
                setOpenChildLink(false);
                setActiveMenu(item);
              }}
            >
              <Link
                to={item?.lien}
                className={clsx(
                  {
                    "text-white bg-black":
                      space && space !== 'admin' && tague.indexOf(item?.tague) > -1,
                    "text-white bg-admin":
                      space && space === 'admin' && tague.indexOf(item?.tague) > -1,
                    "hover:text-white hover:bg-black": space & space !== 'admin',
                    "hover:text-white hover:bg-admin": space & space === 'admin',
                  },
                  "group flex gap-x-3 rounded-md py-2 leading-6 pl-2 text-lg text-black"
                )}
              >
                <div
                  className={clsx(
                    {
                      "text-white bg-black":
                        space === "paris" && tague.indexOf(item?.tague) > -1,
                      "text-white bg-medical":
                        space === "medical" && tague.indexOf(item?.tague) > -1,
                      "text-white bg-service":
                        space === "service" && tague.indexOf(item?.tague) > -1,
                      "text-white bg-admin":
                        space === "admin" && tague.indexOf(item?.tague) > -1,
                    },
                    "flex items-center gap-3"
                  )}
                >
                  {item?.icon}
                  {sidebarTextShow && (
                    <span className={"hidden md:inline"}> {item?.libelle}</span>
                  )}
                  <span className={" inline md:hidden"}> {item?.libelle}</span>
                </div>
              </Link>
            </li>
          )}
        </>
      )}
    </>
  );
};
