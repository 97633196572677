import React from 'react'

export default function Filter({ value, onChange }) {
    return (
        <div className="p-3 bg-gray-100 rounded-lg">
            <div className="flex-wrap justify-between md:flex">
                <div className="w-full md:w-[230px] mb-6 md:mb-0">
                    <label className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase" for="description">
                        Description
                    </label>
                    <input
                        className="block w-full px-4 py-2 mb-3 text-sm leading-tight text-gray-700 bg-white border border-red-500 rounded-lg appearance-none focus:outline-none focus:bg-white"
                        id="description"
                        type="text"
                        placeholder=""
                        value={value.description}
                        onChange={e => {
                            onChange("description", e.target.value)
                        }}
                    />
                </div>
                <div className="w-full md:w-[230px] mb-6 md:mb-0">
                    <label className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase" for="mawb">
                        MAWB
                    </label>
                    <input
                        className="block w-full px-4 py-2 mb-3 text-sm leading-tight text-gray-700 bg-white border border-red-500 rounded-lg appearance-none focus:outline-none focus:bg-white"
                        id="mawb"
                        type="text"
                        placeholder=""
                        value={value.mawb}
                        onChange={e => {
                            onChange("mawb", e.target.value)
                        }}
                    />
                </div>
                <div className="w-full md:w-[230px] mb-6 md:mb-0">
                    <label className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase" for="ex1">
                        EX1
                    </label>
                    <input
                        className="block w-full px-4 py-2 mb-3 text-sm leading-tight text-gray-700 bg-white border border-red-500 rounded-lg appearance-none focus:outline-none focus:bg-white"
                        id="ex1"
                        type="text"
                        placeholder=""
                        value={value.ex1}
                        onChange={e => {
                            onChange("ex1", e.target.value)
                        }}
                    />
                </div>
                <div className="w-full md:w-[230px] mb-6 md:mb-0">
                    <label className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase" for="viavol">
                        VIAVOL
                    </label>
                    <input
                        className="block w-full px-4 py-2 mb-3 text-sm leading-tight text-gray-700 bg-white border border-red-500 rounded-lg appearance-none focus:outline-none focus:bg-white"
                        id="viavol"
                        type="text"
                        placeholder=""
                        value={value.viavol}
                        onChange={e => {
                            onChange("viavol", e.target.value)
                        }}
                    />
                </div>
                <div className="w-full md:w-[230px] mb-6 md:mb-0">
                    <label className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase" for="datemanifest">
                        Date Manifest
                    </label>
                    <input
                        className="block w-full px-4 py-2 mb-3 text-sm leading-tight text-gray-700 bg-white border border-red-500 rounded-lg appearance-none focus:outline-none focus:bg-white"
                        id="datemanifest"
                        type="date"
                        placeholder=""
                        value={value.datemanifest}
                        onChange={e => {
                            onChange("datemanifest", e.target.value)
                        }}
                    />
                </div>
            </div>
        </div>
    )
}
