import React from 'react'
import { formatDefaultDate } from '../../utils';
import LoaderTableau from '../tableauComponent/LoaderTableau';

export default function Lists({ items, onSelect, onRowSelect, onDelete, isLoading }) {

    return (

        <div className="mb-5 overflow-auto ">
            <div className="inline-block min-w-full py-2 align-middle ">
                <table className="w-full divide-y divide-gray-300">
                    <thead className={"bg-[#ECECEC]"}>
                        <tr className={" text-black "}>
                            <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3  text-base font-normalsm:pl-0 text-center hover:cursor-pointer "
                            >#</th>
                            {tableHead.map((item, index) => (
                                <th

                                    key={item}
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3  text-base font-normalsm:pl-0 text-center hover:cursor-pointer "
                                >
                                    <div className={"flex items-center gap-1"}>
                                        {" "}
                                        {item.toUpperCase()}

                                    </div>
                                </th>
                            ))}
                            <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3  text-base font-normalsm:pl-0 text-center hover:cursor-pointer "
                            ></th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {isLoading ? (
                            <>
                                <tr>
                                    <td colSpan={tableHead.length}>
                                        <LoaderTableau />
                                    </td>
                                </tr>
                            </>
                        ) : (
                            <>
                                {items.length > 0 ? (
                                    <>
                                        {items.map((item) => (
                                            <tr key={item.id} className="py-2 text-lg cursor-pointer">
                                                <td onClick={() => {
                                                    onSelect(item)
                                                }}>{item.id}</td>
                                                <td
                                                    onClick={() => {
                                                        onSelect?.(item)
                                                        onRowSelect?.(item)
                                                    }}
                                                >{item.description}</td>
                                                <td
                                                    onClick={() => {
                                                        onSelect?.(item)
                                                        onRowSelect?.(item)
                                                    }}
                                                >{item.mawb}</td>
                                                <td onClick={() => {
                                                    onSelect?.(item)
                                                    onRowSelect?.(item)
                                                }}>{item.ex1}</td>
                                                <td onClick={() => {
                                                    onSelect?.(item)
                                                    onRowSelect?.(item)
                                                }}>{item.viavol}</td>
                                                <td>{
                                                    formatDefaultDate(item.datemanifest)
                                                }</td>
                                                <td>{formatDefaultDate(item.created_at)}</td>
                                                <td>
                                                    <div className="flex items-center">
                                                        <div
                                                            className="text-green-600 cursor-pointer text-[14px] mr-5"
                                                            onClick={() => {
                                                                onSelect(item, true)
                                                            }} title="Editer"
                                                        >
                                                            <i className="fas fa-edit"></i>
                                                        </div>

                                                        <div
                                                            className="text-red-500 cursor-pointer text-[14px]"
                                                            onClick={() => {
                                                                onDelete(item)
                                                            }}
                                                            title="Supprimer"
                                                        >
                                                            <i className="fas fa-trash"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                ) : (
                                    <tr>
                                        <td colSpan={tableHead.length}>
                                            <div classname={"py-4 rounded-lg bg-white "}>
                                                <p
                                                    className={
                                                        "my-4 text-xl font-medium text-center text-black "
                                                    }
                                                >
                                                    Vous n'aviez pas de manifest pour le moment
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}


const tableHead = [
    "",
    "Description",
    "MAWB",
    "EX1",
    "VIAVOL",
    "DATE MANIFEST",
    "AJOUTE LE",
    "ACTIONS",

]