import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { urlSplit } from "../../../../utils/Functions/otherFunctions.js";
import useAuth from "../../../../hooks/useAuth.jsx";
import HeaderComponent from "../../../header/header.component.jsx";
import factureService from "../../../../services/facture/factureService.js";
import ClientSelector from "../../../Utils/Selectors/ClientSelector.jsx";
import GlobalList from "./Liste.jsx";
import PaginationComponent from "../../../paginationComponent/PaginationComponent.jsx";



export const IndexListGlobale = ({
    title,
    subtitle,
}) => {

    const { exnetSpace } = urlSplit()
    const { pathname } = useLocation();
    const { isAuthenticated, user } = useAuth();
    const [titlePage, settitlePage] = useState("");
    const [subtitlePage, setsubtitlePage] = useState("");
    const [page, setPage] = useState(1);
    const [billingCenter, setBillingCenter] = useState(0);
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [currentPage, setCurrentPage] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [perPageItems, setperPageItems] = useState(25);

    const handlePageClick = (event) => {
        console.log(event.selected, event)
        setPage(parseInt(event))
        setCurrentPage(event.selected);
    };

    const [data, setData] = useState({
        data: [],
    });
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        settitlePage(title);
        setsubtitlePage(subtitle);
    });


    const { milieu: type } = useParams();

    const fetchData = async (searchData = null) => {
        setIsLoading(true);
        try {


            let params = {
                page: page,
                per_page: process.env.REACT_APP_DATA_PER_PAGE,
                id: parseInt(billingCenter)
            };

            if (startDate && startDate !== "") {
                params.startDate = startDate
            }

            if (endDate && endDate !== "") {
                params.endDate = endDate
            }


            // appel à au service
            const { data: resData } = await factureService.globaleList(params);

            if (resData.success) {
                const lastPage = resData.data.last_page;
                const newData = resData.data.data
              
                setData((d) => ({
                    data: [...newData],
                }));

                setTotalItems(parseInt(resData.data.total))

                setIsLoading(false);
            }
            else {
                setIsLoading(false);
                console.log(resData)
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const results = data.data;
    // Premier useEffect pour charger les données initiales
    useEffect(() => {
        let table = pathname.split('/');
        let tableValue = table[table.length - 1]

        setIsLoading(true);
        setPage(1);
        let value = {
            data: [],
        };
        setData({ ...value });
        fetchData();
    }, []);



    // Deuxième useEffect pour gérer les mises à jour de page et de recherche
    useEffect(() => {
        fetchData();
    }, [page,perPageItems]);



    useEffect(() => {
        if (billingCenter && billingCenter !== "") {
            setIsLoading(true);
            setPage(1);
            let value = {
                data: [],
            };
            setData({ ...value });
            fetchData();
        }

        if (startDate && startDate !== "") {
            fetchData();
        }

        if (endDate && endDate !== "") {
            fetchData();
        }

    }, [billingCenter, startDate, endDate])


    return (
        <div>
            <div className="flex items-center justify-between">
                <HeaderComponent title={titlePage} subtitle={subtitlePage} />
            </div>

            <div className="p-3 bg-gray-100 rounded-md">
                <div className="flex-wrap items-center md:flex">
                    <div className="w-full md:w-[230px] md:mb-0 pt-2 mb-4 mr-4">
                        <label
                            className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                            htmlFor="billing_center"
                        >
                            Centre du client
                        </label>
                        <ClientSelector
                            selectedValue={billingCenter}
                            showLabel={false}
                            profil={"customer"}
                            onSelect={(v) => {

                                setBillingCenter(v.value)
                            }}
                        />
                        {/* <BillCenterSelector type={'paris'} user={false}
                            onSelect={(v) => {
                                setBillingCenter(v.value)
                            }}
                        /> */}
                    </div>

                    <div className="w-full md:w-[230px] mb-6 md:mb-0  mr-4">
                        <label
                            className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                            htmlFor="startDate"
                        >
                            Date de création
                        </label>
                        <input
                            className="block text-sm h-[40px] w-full  px-4 py-2 mb-3 leading-tight text-gray-700 bg-white border border-red-500 rounded-lg appearance-none focus:outline-none focus:bg-white"
                            id="startDate"
                            type="date"
                            placeholder=""
                            value={startDate}
                            onChange={(e) => {
                                setStartDate(e.target.value)

                            }}
                        />
                    </div>

                    <div className="w-full md:w-[230px] mb-6 md:mb-0">
                        <label
                            className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                            htmlFor="endDate"
                        >
                            Date de fin
                        </label>
                        <input
                            className="block text-sm h-[40px] w-full  px-4 py-2 mb-3 leading-tight text-gray-700 bg-white border border-red-500 rounded-lg appearance-none focus:outline-none focus:bg-white"
                            id="endDate"
                            type="date"
                            placeholder=""
                            value={endDate}
                            onChange={(e) => {
                                setEndDate(e.target.value)
                            }}
                        />
                    </div>


                </div>
            </div>

            <div>
                <PaginationComponent
                    handlePageClick={handlePageClick}
                    totalItems={totalItems}
                    perPageItems={perPageItems}
                    setnumberPerPage={setperPageItems}
                    page={page}
                />
            </div>

            <GlobalList items={results} setItems={setData} isLoading={isLoading} />

            <div>
                <PaginationComponent
                    handlePageClick={handlePageClick}
                    totalItems={totalItems}
                    perPageItems={perPageItems}
                    setnumberPerPage={setperPageItems}
                    page={page}
                />
            </div>



        </div>
    );
}
