import { PlusIcon } from "@heroicons/react/24/outline";

const { useParams, useLocation, Link } = require("react-router-dom");
const { default: ExpeditionService } = require("../services/expedition/ExpeditionService");
const { useState, useEffect } = require("react");
const { useAppContext } = require("../context");
const { default: useDisclosure } = require("../hooks/useDisclosure");
const { toast } = require("react-toastify");
const { default: HeaderComponent } = require("./header/header.component");
const { default: Filter } = require("./Tableaux/ExpeditionsClient/FilterExpedition");
const { default: PaginationComponent } = require("./paginationComponent/PaginationComponent");
const { default: AttributionFormModal } = require("./Tableaux/ExpeditionsClient/Form");
const { default: DeletionConfirm } = require("./Tableaux/ExpeditionsClient/DeletionConfirm");
const { default: ExpeditionLists } = require("./Tableaux/ExpeditionsClient/ListExpeditionClient");
const { default: InformationModal } = require("./Tableaux/Expeditions/DispatchFormModal");

export const ExpeditionsClientView = ({
    category,
    type_expedition,
    route,
    title,
    subtitle,
    subRoute = false
  }) => {
    const [isSearch, setisSearch] = useState(false);
    const [filterData, setfilterData] = useState({});
    const { pathname } = useLocation();
    const splitPathname = pathname.split("/");
    const { isAuth, setIsAuth } = useAppContext();
    const [titlePage, settitlePage] = useState("");
    const [subtitlePage, setsubtitlePage] = useState("");
    const [page, setPage] = useState(1);
    const [selectedItem, setSelectedItem] = useState();
    const [editMode, setEditMode] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [data, setData] = useState({
      data: [],
    });
    const {
      isOpen: isAttributionOpen,
      onClose: onAttributionClose,
      onOpen: onAttributionOpen,
    } = useDisclosure();
  
    const {
      isOpen: isDeleteConfirmOpen,
      onClose: onDeleteConfirmClose,
      onOpen: onDeleteConfirmOpen,
    } = useDisclosure();
  
    const {
      isOpen: isDispatchModalOpen,
      onClose: onDispatchModalClose,
      onOpen: onDispatchModalOpen,
    } = useDisclosure();
  
    const { milieu: type, ExpeditionType } = useParams();
  
    const [isLoading, setIsLoading] = useState(true);
  
    const [attributionProfile, setAttributionProfile] = useState();
    const [perPageItems, setperPageItems] = useState(25);
  
    const [filter, setFilter] = useState({
      ref: "",
      suivi: "",
      contact: "",
      billing_center: "",
      delivery_date: "",
      pickup_date: "",
      code_donneur: "",
      code_receveur: "",
    });
  
    const fetchData = async (searchData = null, laPage = null) => {
      setIsLoading(true)
      try {
  
        const type_de_roue = isAuth.les_2 === 1 ? "2, 4" : isAuth.vt === 1 ? "4" : isAuth["2_roues"] === 1 ? "2" : undefined;
  
        let params = {
          type: isAuth?.spaceName,
          per_page: parseInt(perPageItems),
          page: page,
          user_id: isAuth?.id,
          category: category,
          // ExpeditionType:ExpeditionType==='courses-a-courses'?'course':'expedition',
          type_expedition: route === 'all' ? 'all' : ExpeditionType === 'courses-a-courses' ? 'courses' : 'expedition',
        };
  
        if (type_de_roue) {
          params.type_roue = type_de_roue
        }
  
        if (searchData) {
          params["filter"] = searchData;
        }
  
        if (subRoute) {
          params['status'] = subRoute
        }
        alert('salut')
        const { data: resData } = await ExpeditionService.getAllClientExpedition(
          params,
          route
        );
  
        setIsLoading(false)
        if (resData.success) {
  
          setTotalItems(parseInt(resData.data.total))
  
          let value = {
            data: [...resData.data.data]
          }
          setData({ ...value });
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false)
      } finally {
        setIsLoading(false);
      }
    };
  
    const onSubmit = (result) => {
      let items = [...data.data];
      const resultIndex = items.findIndex((i) => i.id === result?.id);
      const isUpdate = resultIndex > -1;
      if (isUpdate) {
        items[resultIndex] = result;
      } else {
        items = [result, ...items];
      }
  
      setData((d) => ({
        ...d,
        data: items,
      }));
  
      toast.success(
        `${isUpdate ? "Détails mis à jour" : "Données ajoutées"} avec succès!`,
        {
          position: "bottom-center",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    };
  
    const onItemSelected = (item, isEditable) => {
      if (isEditable) {
        setEditMode(true);
      }
      setSelectedItem(item);
      onAttributionOpen();
    };
  
    const handleClose = () => {
      setSelectedItem(null);
      onAttributionClose();
      onDispatchModalClose();
      setEditMode(false);
    };
  
    const handleDeleteModalClose = () => {
      onDeleteConfirmClose();
    };
  
    const onDelete = (item) => {
      setSelectedItem(item);
      onDeleteConfirmOpen();
    };
  
    const onDeleted = (item) => {
      const items = [...data.data];
      const index = items.findIndex((i) => i.id === item?.id);
      if (index > -1) {
        items.splice(index, 1);
        setData((d) => ({
          ...d,
          data: items,
        }));
      }
      onDeleteConfirmClose();
      setSelectedItem(undefined);
    };
  
    const results = data.data.filter((d) => {
      const {
        ref,
        suivi,
        contact,
        billing_center,
        delivery_date,
        pickup_date,
        code_donneur,
        code_receveur,
      } = filter;
      // alert(d.ref)
  
      const refMatch = d?.ref
        ?.toString()
        .toLowerCase()
        .includes(ref.toString().toLowerCase());
  
      const codeDonneurMatch =
        code_donneur.length > 0
          ? d?.code_donneur
            ?.toLowerCase()
            .includes(code_donneur?.toLowerCase() || null)
          : true;
  
      const codeReceveursMatch =
        code_receveur.length > 0
          ? d?.code_receveur
            ?.toLowerCase()
            .includes(code_receveur?.toLowerCase() || null)
          : true;
  
      const suiviMatch = d?.suivi?.toLowerCase().includes(suivi.toLowerCase());
  
      const contactMatch = d?.contact
        ?.toLowerCase()
        .includes(contact.toLowerCase());
  
  
      // const billing_centerMatch = d?.billing_center
      //   ?.toLowerCase()
      //   .includes(billing_center?.toLowerCase());
  
      const deliverDateMatch = d?.delivery_date
        ?.toLowerCase()
        .includes(delivery_date.toLowerCase());
  
      const pickupDateMatch = d?.pickup_date
        ?.toLowerCase()
        .includes(pickup_date.toLowerCase());
  
      return codeDonneurMatch && codeReceveursMatch && refMatch;
    });
  
    const onAttributionSelected = (item, profile) => {
      setSelectedItem(item);
      setAttributionProfile(profile);
      onAttributionOpen();
    };
  
    const onDispatchModalSelected = (item) => {
      setSelectedItem(item);
      onDispatchModalOpen();
    };
  
    const handleSearch = () => {
      setIsLoading(true);
      setisSearch(true)
      setPage(1);
      fetchData(filterData, 1);
    }
  
  
    const revokeSearch = () => {
      setfilterData({})
      setIsLoading(true);
      setisSearch(false)
      setPage(1);
      fetchData();
    }
  
  
    // Premier useEffect pour charger les données initiales
    useEffect(() => {
      settitlePage(title);
      setsubtitlePage(subtitle);
      setIsLoading(true);
      if (pathname) {
        fetchData();
      }
    }, []);
    const handlePageClick = (event) => {
      console.log(event)
      setPage(parseInt(event))
      setCurrentPage(event);
    };
  
  
    // Deuxième useEffect pour gérer les mises à jour de page et de recherche
    useEffect(() => {
      if (isSearch) {
        fetchData(filterData);
      } else if (!isSearch) {
        fetchData();
      }
  
    }, [page, currentPage, perPageItems]);
  
  
  
  
  
    return (
      <div>
  
        <div className="flex items-center justify-between">
  
          <HeaderComponent title={titlePage} subtitle={subtitlePage} />
          {![
            "en-cours",
            "livre",
            "a-archivee",
            "facturee",
            "annulee",
            "en-attente",
          ].includes(splitPathname[4]) &&
            splitPathname[4] && (
              <Link
                to={`/${splitPathname[1]}/${splitPathname[2]}/dash/${splitPathname[4]
                  ? splitPathname[4]
                  : "en-cours".toLowerCase().replace(/ /g, "-")
                  }/new`}
                className="float-right btn btn-primary btn-rounded"
              >
               <PlusIcon  className={"h-4 w-4 m-3 text-xl font-bold "}/>
              </Link>
            )}
        </div>
  
        <Filter
          type={type}
          setfilterData={setfilterData}
          filterData={filterData}
          revokeSearch={revokeSearch}
          handleSearch={handleSearch}
        />
  
        <div>
          <PaginationComponent
            page={page}
            handlePageClick={handlePageClick}
            totalItems={totalItems}
            perPageItems={perPageItems}
            setnumberPerPage={setperPageItems}
          />
        </div>
  
        <ExpeditionLists
          type_expedition={type_expedition}
          items={results}
          setItems={(value) => {
            setData(d => ({
              ...d,
              data: value
            }))
          }}
          onSelect={onItemSelected}
          onAttributionSelect={onAttributionSelected}
          onDelete={onDelete}
          onDispatchModalSelect={onDispatchModalSelected}
          type={type}
          pathname={splitPathname}
          isLoading={isLoading}
        />
  
        <div>
          <PaginationComponent
            page={page}
            element="pagination"
            handlePageClick={handlePageClick}
            totalItems={totalItems}
            perPageItems={perPageItems}
            setnumberPerPage={setperPageItems}
          />
        </div>
        <AttributionFormModal
          onSubmit={onSubmit}
          selectedData={selectedItem}
          isOpen={isAttributionOpen}
          onClose={handleClose}
          editable={editMode}
          type={type}
          selectedProfile={attributionProfile}
        />
  
        <InformationModal
          selectedData={selectedItem}
          isOpen={isDispatchModalOpen}
          onClose={handleClose}
        />
  
        <DeletionConfirm
          onSubmit={onDeleted}
          selectedData={selectedItem}
          isOpen={isDeleteConfirmOpen}
          onClose={handleDeleteModalClose}
          title={"Etes-vous sûr de vouloir supprimer ce manifest?"}
        />
      </div>
    );
  }
  