import React from 'react';
import UserDetails from '../../components/resume/userDetails';
import ShipmentDetails from '../../components/resume/shipmentDetails';
import PackageDetails from '../../components/resume/packageDetails';
import AccountDetails from '../../components/resume/AccountDetails';
import { useState } from 'react'
import { Switch } from '@headlessui/react'

function ResumePage(props) {
    return (
        <div className='p-5'>
            <div className={'text-center'}>
                <span className='block text-2xl font-semibold uppercase'>résumé de votre commande </span>
                <span className={'w-32 block border-b-2 border-b-black text-center mx-auto mt-4 '}></span>

            </div>
            <section className={'my-6 '}>
                <AccountDetails />
            </section>

            <section className='grid-cols-2 gap-3 my-6 space-y-4 md:grid md:space-y-0'>

                <UserDetails title={"Détails de l'expéditeur"} onClick={() => console.log("ccc")} />

                <UserDetails title={"Détails de l'expéditeur"} onClick={() => console.log("ccc")} />

            </section>

            <section className={'my-6 '}>
                <ShipmentDetails />
            </section>

            <section className={'my-6 '}>
                <PackageDetails />
            </section>

            <section>
                <Toggle />
            </section>

            <section>
                <div className="relative flex gap-x-3">
                    <div className="flex items-center h-6">
                        <input
                            id="comments"
                            name="comments"
                            type="checkbox"
                            className="w-4 h-4 text-indigo-600 border-gray-300 rounded-lg focus:ring-indigo-600"
                        />
                    </div>
                    <div className="text-sm leading-6">
                        <label htmlFor="comments" className="font-medium text-gray-900">
                            En cochant cette case, je reconnais avoir pris
                            connaissance des{" "}
                            <span className={" underline "}>
                                Conditions Générales de Vente et de Transport EXNET
                            </span>{" "}
                        </label>
                        {/* <p className="text-gray-500">Get notified when someones posts a comment on a posting.</p> */}
                    </div>
                </div>

                <div className="relative flex gap-x-3">
                    <div className="flex items-center h-6">
                        <input
                            id="comments"
                            name="comments"
                            type="checkbox"
                            className="w-4 h-4 text-indigo-600 border-gray-300 rounded-lg focus:ring-indigo-600"
                        />
                    </div>
                    <div className="text-sm leading-6">
                        <label htmlFor="comments" className="font-medium text-gray-900">
                            En cochant cette case, je reconnais avoir pris
                            connaissance du{" "}
                            <span className={"underline"}>
                                traitement des données personnelles
                            </span>{" "}
                            du site, et les accepte sans réserve.
                        </label>
                        {/* <p className="text-gray-500">Get notified when someones posts a comment on a posting.</p> */}
                    </div>
                </div>

            </section>
        </div>
    );
}

export default ResumePage;





function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function Toggle() {
    const [enabled, setEnabled] = useState(false)

    return (
        <>
            <div className='flex items-center justify-end gap-3' >
                <span className='font-bold' >Programmer une commande retour</span>
                <Switch
                    checked={enabled}
                    onChange={setEnabled}
                    className="relative inline-flex items-center justify-center flex-shrink-0 w-10 h-5 rounded-full cursor-pointer group focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                >
                    <span className="sr-only">Use setting</span>
                    <span aria-hidden="true" className="absolute w-full h-full bg-white rounded-md pointer-events-none" />
                    <span
                        aria-hidden="true"
                        className={classNames(
                            enabled ? 'bg-indigo-600' : 'bg-gray-200',
                            'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out'
                        )}
                    />
                    <span
                        aria-hidden="true"
                        className={classNames(
                            enabled ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out'
                        )}
                    />
                </Switch>
            </div>

        </>

    )
}
