import {
  TbAddressBook,
  TbBarcode,
  TbCar,
  TbChartLine,
  TbFileInvoice,
  TbHomeDollar,
  TbInfoCircle,
  TbLayoutDashboard,
  TbPackage,
  TbUser,
  TbUserExclamation,
  TbUsers,
  TbUsersGroup,
} from "react-icons/tb";
import * as wi from "react-icons/wi";
import { IoIosTimer } from "react-icons/io";

export const menuAdmin = [
  {
    isTitle: false,
    tague: "dashboard",
    isMultiple: false,
    libelle: "Dashboard",
    lien: "/admin/dash",
    icon: (
      <i>
        <TbLayoutDashboard />
      </i>
    ),
  },
  {
    isTitle: true,
    libelle: "Services",
    color: "#098721",
  },
  {
    isTitle: false,
    tague: "clients",
    isMultiple: false,
    libelle: "Clients",
    lien: "/admin/dash/clients",
    icon: (
      <i>
        <TbUsersGroup />
      </i>
    ),
  },
  {
    isTitle: false,
    tague: "centre-facturation",
    isMultiple: false,
    libelle: "Centres de facturation",
    lien: "/admin/dash/centre-facturation",
    icon: (
      <i>
        <TbHomeDollar />{" "}
      </i>
    ),
  },
  {
    isTitle: false,
    tague: "utilisateurs",
    isMultiple: false,
    libelle: "Utilisateurs",
    lien: "/admin/dash/utilisateurs",
    icon: (
      <i>
        <TbUsersGroup />
      </i>
    ),
  },
  {
    isTitle: false,
    tague: "contacts",
    isMultiple: false,
    libelle: "Contacts",
    lien: "/admin/dash/contacts",
    icon: (
      <i>
        <TbAddressBook />{" "}
      </i>
    ),
  },
  {
    isTitle: false,
    tague: "dashboard",
    isMultiple: false,
    libelle: "Fournisseurs",
    lien: "/admin/dash/soustraitants",
    icon: (
      <i>
        <TbUser />{" "}
      </i>
    ),
  },
  {
    isTitle: false,
    tague: "historiques",
    isMultiple: false,
    libelle: "Le 24 Heures",
    lien: "/admin/dash/historiques",
    icon: (
      <i>
        <IoIosTimer />
      </i>
    ),
  },
  {
    isTitle: true,
    libelle: "Gestion facture",
    color: "#222",
  },
  {
    isTitle: false,
    tague: "fuel",
    isMultiple: false,
    libelle: "Fuels",
    lien: "/admin/dash/facture/fuel",
    icon: (
      <i>
        <wi.WiRaindrop />{" "}
      </i>
    ),
  },
  {
    isTitle: false,
    tague: "code-facturation",
    isMultiple: false,
    libelle: "Codes de facturation",
    lien: "/admin/dash/facture/code-facturation",
    icon: (
      <i>
        <TbBarcode />{" "}
      </i>
    ),
  },
  {
    isTitle: true,
    libelle: "Statistiques",
    color: "#4361ee",
  },
  {
    isTitle: false,
    tague: "expeditions",
    isMultiple: false,
    libelle: "Courses",
    lien: "/admin/dash/statiques/expeditions",
    icon: (
      <i>
        <TbPackage />{" "}
      </i>
    ),
  },
  {
    isTitle: false,
    tague: "freight",
    isMultiple: false,
    libelle: "Freight",
    lien: "/admin/dash/statiques/freight",
    icon: (
      <i>
        <TbChartLine />{" "}
      </i>
    ),
  },
  {
    isTitle: true,
    libelle: "Configuration / Gestion",
    color: "#ff595e",
  },
  {
    isTitle: false,
    tague: "trackinh-template",
    isMultiple: false,
    libelle: "Tracking templates",
    lien: "/admin/dash/tracking-template",
    icon: (
      <i>
        <TbFileInvoice />{" "}
      </i>
    ),
  },
  {
    isTitle: false,
    tague: "transport-types",
    isMultiple: false,
    libelle: "Type de transports",
    lien: "/admin/dash/transport-types",
    icon: (
      <i>
        <wi.WiRaindrop />{" "}
      </i>
    ),
  },
  {
    isTitle: false,
    tague: "vehicules",
    isMultiple: false,
    libelle: "Véhicules",
    lien: "/admin/dash/vehicules",
    icon: (
      <i>
        <TbCar />{" "}
      </i>
    ),
  },
  {
    isTitle: false,
    tague: "salaries",
    isMultiple: false,
    libelle: "Salariés",
    lien: "/admin/dash/salaries",
    icon: (
      <i>
        <TbUser />{" "}
      </i>
    ),
  },
  {
    isTitle: false,
    tague: "administrateurs",
    isMultiple: false,
    libelle: "Admin",
    lien: "/admin/dash/administrateurs",
    icon: (
      <i>
        <TbUserExclamation />{" "}
      </i>
    ),
  },
  {
    isTitle: false,
    tague: "ops",
    isMultiple: false,
    libelle: "OPS",
    lien: "/admin/dash/ops",
    icon: (
      <i>
        <TbUsers />{" "}
      </i>
    ),
  },
  {
    isTitle: false,
    tague: "chauffeurs",
    isMultiple: false,
    libelle: "Chauffeurs",
    lien: "/admin/dash/chauffeurs",
    icon: (
      <i>
        <TbUser />{" "}
      </i>
    ),
  },
  {
    isTitle: false,
    tague: "information",
    isMultiple: false,
    libelle: "Information",
    lien: "/admin/dash/information",
    icon: (
      <i>
        <TbInfoCircle />{" "}
      </i>
    ),
  },
];
