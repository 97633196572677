import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import useAuth from "../../../hooks/useAuth";
import HeaderComponent from "../../header/header.component.jsx";
import ListFacture from "./ListFacture";
import FactureService from "../../../services/facture/factureService";
import { LinkData, urlSplit } from "../../../utils/Functions/otherFunctions";
import ExpeditionService from "../../../services/expedition/ExpeditionService";
import ListGlobale from "./ListGlobale.jsx";
import ExtractionList from "./ExtractionList.jsx";
import BillCenterSelector from "../../Utils/Selectors/BillCenterSelector.jsx";
import PaginationComponent from "../../paginationComponent/PaginationComponent.jsx";

export const FactureListPage = ({ title, subtitle, type }) => {
  const { pathname } = useLocation();
  return (
    <IndexFacture
      title={title}
      subtitle={subtitle}
      type={type}
      key={"gfhgjjkkkqsxfd"}
    />
  );
};

export const FactureExpeditionArchiveePage = ({ title, subtitle, type }) => {
  const { pathname } = useLocation();
  return (
    <IndexFacture
      title={title}
      subtitle={subtitle}
      type={type}
      key={"jkkhghgf"}
    />
  );
};

export const ExtractionFacturePage = ({ title, subtitle, type }) => {
  const { pathname } = useLocation();
  return (
    <IndexFacture
      title={title}
      subtitle={subtitle}
      type={type}
      key={"fdfejkj"}
    />
  );
};

export const FactureGlobalePage = ({ title, subtitle, type }) => {
  const { pathname } = useLocation();
  return (
    <IndexFacture title={title} subtitle={subtitle} type={type} key={"fdgfg"} />
  );
};

const IndexFacture = ({ title, subtitle, type: typeListe }) => {
  const { exnetSpace } = urlSplit();
  const [isSearch, setisSearch] = useState(false);
  const [filterData, setfilterData] = useState({});
  const { pathname } = useLocation();
  const splitPathname = pathname.split("/");
  const { isAuthenticated, user } = useAuth();
  const [titlePage, settitlePage] = useState("");
  const [subtitlePage, setsubtitlePage] = useState("");
  const [ListeType, setListeType] = useState("");
  const [page, setPage] = useState(1);
  const [billingCenter, setBillingCenter] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [perPageItems, setperPageItems] = useState(25);

  const [pageList, setpageList] = useState([]);

  useEffect(() => {
    settitlePage(title);
    setsubtitlePage(subtitle);
  });

  const { milieu: type } = useParams();

  const [data, setData] = useState({
    data: [],
  });

  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState({
    ref: "",
    suivi: "",
    contact: "",
    billing_center: "",
    delivery_date: "",
    pickup_date: "",
    code_donneur: "",
    code_receveur: "",
  });

  const lien = window.location.pathname.split("/");

  const fetchData = async (searchData = null) => {
    try {
        alert("salut")
      let params = {
        page: page,
        per_page: process.env.REACT_APP_DATA_PER_PAGE,
      };

      if (
        ListeType === "extractions" &&
        billingCenter &&
        billingCenter !== ""
      ) {
        params.id = parseInt(billingCenter);
      }

      let { role, espace, typeExpedition, etatExpedition } = LinkData(lien);

      // si loption de recherche est lancée
      if (searchData) {
        params["filter"] = searchData;
      }

      params["type"] = espace;

      if (typeListe === "expedition") {
        params["category"] = "all";
        params["type_expedition"] = "all";
      }

      // appel à au service
      // const { data: resData } = (typeListe === "facture") ? await FactureService.fetchFactures(params) :
      //     (
      //         typeListe === "expedition" ? await ExpeditionService.getAllOpsExpeditionsArchiver(params) : []
      //     );
      console.log('{...params,  type_expedition:"all"}', {
        ...params,
        type_expedition: "all",
      });
      alert("salut la eam")
      const { data: resData } =
        ListeType === "extractions"
          ? await FactureService.extractionList(params)
          : ListeType === "liste-globale"
          ? await FactureService.globaleList(params)
          : ListeType === "bordereaux"
          ? await ExpeditionService.getAllOpsExpeditionsArchiver({
              ...params,
              type_expedition: "all",
            })
          : await FactureService.fetchFactures(params);

      if (resData.success) {
        const lastPage = resData.data.last_page;
        const newData = resData.data.data;
        // .filter((facture) => {
        //     return !data.data.some(
        //         (item) => parseInt(item.id) === parseInt(facture.id)
        //     );
        // });

        setData((d) => ({
          data: [...d.data, ...newData],
        }));
        setIsLoading(false);
        setTotalItems(parseInt?.total);

        // // Increment the page only if it's less than the last page
        // if (page < lastPage) {
        //     setPage(page + 1);
        //     let newPage = [...pageList];
        //     newPage.push(page);
        //     setpageList([...newPage]);
        // }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const results = data.data;
  // Premier useEffect pour charger les données initiales
  useEffect(() => {
    let table = pathname.split("/");
    let tableValue = table[table.length - 1];

    if (tableValue === "extractions") {
      setListeType("extractions");
    } else if (tableValue === "liste-globale") {
      setListeType("liste-globale");
    } else if (tableValue === "bordereaux") {
      setListeType("bordereaux");
    }

    setIsLoading(true);
    let value = {
      data: [],
    };
    setData({ ...value });
    fetchData();
  }, [pathname, type]);

  // Deuxième useEffect pour gérer les mises à jour de page et de recherche
  useEffect(() => {
    if (page > 1) {
      if (isSearch) {
        fetchData(filterData);
      } else if (!isSearch) {
        fetchData();
      }
    }
  }, [page]);

  const handleSearch = () => {
    setIsLoading(true);
    setisSearch(true);
    let value = {
      data: [],
    };
    setPage(1);
    setData({ ...value });
    fetchData(filterData);
  };

  const revokeSearch = () => {
    setfilterData({});
    setIsLoading(true);
    setisSearch(false);
    let value = {
      data: [],
    };
    setPage(1);
    setData({ ...value });
    fetchData();
  };

  useEffect(() => {
    if (ListeType === "extractions" && billingCenter && billingCenter !== "") {
      fetchData();
    }
  }, [billingCenter]);

  const handlePageClick = (event) => {
    setPage(parseInt(event));
    setCurrentPage(event);
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <HeaderComponent title={titlePage} subtitle={subtitlePage} />
      </div>

      {/* <FilterFacture
                setfilterData={setfilterData}
                filterData={filterData}
                type={typeListe}
                revokeSearch={revokeSearch}
                handleSearch={handleSearch}
            /> */}

      {ListeType === "extractions" && (
        <>
          <div className="p-3 bg-gray-100 rounded-md">
            <div className="flex-wrap items-center justify-between md:flex">
              <div className="w-full md:w-[230px] md:mb-0 pt-2 mb-4">
                <label
                  className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                  htmlFor="billing_center"
                >
                  Centre de facturation
                </label>
                <BillCenterSelector
                  type={exnetSpace}
                  user={false}
                  onSelect={(v) => {
                    setBillingCenter(v.value);
                  }}
                />
              </div>

              <div className="w-full md:w-[230px] mb-6 md:mb-0">
                <label
                  className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                  htmlFor="startDate"
                >
                  Date de création
                </label>
                <input
                  className="block text-sm h-[40px] w-full  px-4 py-2 mb-3 leading-tight text-gray-700 bg-white border border-red-500 rounded-lg appearance-none focus:outline-none focus:bg-white"
                  id="startDate"
                  type="date"
                  placeholder=""
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                />
              </div>

              <div className="w-full md:w-[230px] mb-6 md:mb-0">
                <label
                  className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                  htmlFor="endDate"
                >
                  Date de fin
                </label>
                <input
                  className="block text-sm h-[40px] w-full  px-4 py-2 mb-3 leading-tight text-gray-700 bg-white border border-red-500 rounded-lg appearance-none focus:outline-none focus:bg-white"
                  id="endDate"
                  type="date"
                  placeholder=""
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div>
            <PaginationComponent
              page={page}
              handlePageClick={handlePageClick}
              totalItems={totalItems}
              perPageItems={perPageItems}
              setnumberPerPage={setperPageItems}
            />
          </div>
          <ExtractionList
            items={results}
            setItems={setData}
            isLoading={isLoading}
            type={typeListe}
          />
          <div>
            <PaginationComponent
              page={page}
              handlePageClick={handlePageClick}
              totalItems={totalItems}
              perPageItems={perPageItems}
              setnumberPerPage={setperPageItems}
            />
          </div>
        </>
      )}

      {ListeType === "liste-globale" && (
        <>
          <div>
            <PaginationComponent
              page={page}
              handlePageClick={handlePageClick}
              totalItems={totalItems}
              perPageItems={perPageItems}
              setnumberPerPage={setperPageItems}
            />
          </div>
          <ListGlobale
            items={results}
            setItems={setData}
            isLoading={isLoading}
            type={typeListe}
          />

          <div>
            <PaginationComponent
              page={page}
              handlePageClick={handlePageClick}
              totalItems={totalItems}
              perPageItems={perPageItems}
              setnumberPerPage={setperPageItems}
            />
          </div>
        </>
      )}

      {ListeType === "bordereaux" && (
        <>
          <div>
            <PaginationComponent
              page={page}
              handlePageClick={handlePageClick}
              totalItems={totalItems}
              perPageItems={perPageItems}
              setnumberPerPage={setperPageItems}
            />
          </div>
          <ListFacture
            items={results}
            setItems={setData}
            isLoading={isLoading}
            type={typeListe}
          />
          <div>
            <PaginationComponent
              page={page}
              handlePageClick={handlePageClick}
              totalItems={totalItems}
              perPageItems={perPageItems}
              setnumberPerPage={setperPageItems}
            />
          </div>
        </>
      )}

      {ListeType === "liste" && (
        <>
          {/* <ListFacture items={results} setItems={setData} isLoading={isLoading} type={typeListe} /> */}
          <div>
            <PaginationComponent
              page={page}
              handlePageClick={handlePageClick}
              totalItems={totalItems}
              perPageItems={perPageItems}
              setnumberPerPage={setperPageItems}
            />
          </div>
          <ListGlobale
            items={results}
            setItems={setData}
            isLoading={isLoading}
            type={typeListe}
          />
          <div>
            <PaginationComponent
              page={page}
              handlePageClick={handlePageClick}
              totalItems={totalItems}
              perPageItems={perPageItems}
              setnumberPerPage={setperPageItems}
            />
          </div>
        </>
      )}

      {/* <ListFacture items={results} setItems={setData} isLoading={isLoading} type={typeListe} /> */}
    </div>
  );
};
