import React, { useCallback, useEffect, useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import LoaderTableau from "../../tableauComponent/LoaderTableau";
import EmptyTableau from "../../tableauComponent/EmptyTableau";
import { useSelector } from "react-redux";
import { TbArrowsUpDown, TbCheck } from "react-icons/tb";
import useFacturations from "../../../states/facturations";
import { trierTableau } from "../../../utils/Functions/tableFilter";
import FacturationModal from "../../Facturation/Modal";
import TableRowComponent from "../../rowComponent/TableRowComponent";
import {
  exnetSpaceColor,
  urlSplit,
} from "../../../utils/Functions/otherFunctions";
import { toast } from "react-toastify";
import ExpeditionService from "../../../services/expedition/ExpeditionService";
import { CoursierDriverSelector } from "../../Utils/Selectors/CoursierDriverSelector";
import { AllDriverSelector } from "../../Utils/Selectors/AllDriverSelector";
import adminService from "../../../services/admin/adminService";
import { TableEntete } from "../../../utils/table/opsTable";
import useDisclosure from "../../../hooks/useDisclosure";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useAppContext } from "../../../context";

export const AttributionComponent = ({
  shipping_id,
  type,
  drivers = [],
  value,
  isDisabled = false,
}) => {
  const [attributedTo, setAttributedTo] = useState(null);
  const [showAddAttrButton, setshowAddAttrButton] = useState(false);

  const AffectToDriver = (id) => {
    ExpeditionService.affectShipping({
      chauffeur_id: attributedTo.value,
      shipping_id: shipping_id,
    }).then(
      (res) => {
        let { success } = res.data;
        if (success) {
          toast.success("Attribution éffectué avec succès", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setshowAddAttrButton(false);
        } else {
          toast.error("Erreur lors de l'attributtion ", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      },
      (error) => {
        toast.error("Erreur lors de l'attributtion ", {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    );
  };

  useEffect(() => {
    if (attributedTo) {
      setshowAddAttrButton(true);
    }
  }, [attributedTo]);

  return (
    <>
      <AllDriverSelector
        driversList={drivers}
        onChange={setAttributedTo}
        value={value}
        isDisabled={isDisabled}
      />

      {showAddAttrButton && (
        <button
          onClick={AffectToDriver}
          style={{
            backgroundColor: `${exnetSpaceColor(type)}`,
          }}
          class="mt-2 text-white text-center font-semibold py-2 px-4 rounded-full shadow-md hover:shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
        >
          <TbCheck />
        </button>
      )}
    </>
  );
};

export default function ExpeditionLists({
  items,
  onSelect,
  onAttributionSelect,
  onDispatchModalSelect,
  onAddTrackingClick,
  type,
  pathname,
  isLoading,
  checkboxVisible,
  coursiersList = null,
  allowCheckBoxToggler,
  getShippingUrl,
  shippingsInAvoir = [],
  linkTarget = "_self",
}) {
  const { exnetSpace } = urlSplit();
  const { pathname: lien } = useLocation();
  const { milieu } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isAuth, setIsAuth } = useAppContext();

  const expeditionStore = useSelector(
    (state) => state.expedition.allExpedition
  );

  const [headers, setHeaders] = useState([]);
  const [filterBy, setfilterBy] = useState("ref");
  const [filterOrder, setfilterOrder] = useState("desc");
  const { selectedRows, setSelectedRows } = useFacturations();

  const filteredArray = items.reduce((uniqueArray, currentItem) => {
    // Check if an object with the same value exists in the uniqueArray
    const existingItem = uniqueArray.find((item) => item.id === currentItem.id);

    // If not found, add the current item to the uniqueArray
    if (!existingItem) {
      uniqueArray.push(currentItem);
    }

    return uniqueArray;
  }, []);

  useEffect(() => {
    const entete = TableEntete(type);

    if (checkboxVisible) {
      //retirer les colonnes attributions et actions
      const newEntete = [{ label: "", cle: null }, ...entete];
      console.log({ newEntete });
      setHeaders(newEntete.filter((_, i) => i < newEntete.length - 2));
    } else {
      setHeaders(entete);
    }
  }, [checkboxVisible, type]);

  //const navigate = useNavigate();

  const [isLoad, setIsLoad] = useState(false);
  const [driversList, setDriversList] = useState([]);

  useEffect(() => {
    const fetchCoursiers = async () => {
      setIsLoad(true);
      const isChauffeur = type === "chauffeurs";

      const { data } = await adminService.getAllDrivers(exnetSpace);
      if (data.success) {
        setDriversList(
          data.data.map((c) => {
            return { label: c?.name, value: c.id };
          })
        );
        setIsLoad(false);
      }
    };

    fetchCoursiers();
  }, [pathname]);

  const sortedArray = trierTableau(filteredArray, filterBy, filterOrder);

  const handleCheckboxChange = useCallback(
    (item) => {
      //console.log(item)
      //check billing center
      if (
        selectedRows.length > 0 &&
        selectedRows.find(
          (s) => s.billing_center?.id !== item?.billing_center?.id
        )
      ) {
        toast.error(`Centres de facturation différents`, {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
      if (selectedRows.map((i) => i.id).includes(item.id)) {
        setSelectedRows(selectedRows.filter((rowId) => rowId.id !== item.id));
      } else {
        setSelectedRows([...selectedRows, item]);
      }
    },
    [selectedRows]
  );

  const selectedShippingIds = selectedRows.map((el) => el.id);

  //selectedRows.map(el => el.id).includes(item.id)

  const lastPath = lien.split("/")[lien.split("/").length - 1];

  const allSelected =
    selectedShippingIds.length > 0 &&
    selectedShippingIds.length === items.length; //items.every(item => item.billing_center_id)

  const toggleAllSelection = useCallback(() => {
    if (allSelected) {
      setSelectedRows([]);
    } else {
      setSelectedRows([...items]);
    }
  }, [allSelected, setSelectedRows, items]);

  const isShippingInAvoir = useCallback(
    (shippingId) => {
      return shippingsInAvoir.includes(shippingId);
    },
    [shippingsInAvoir]
  );

  return (
    <div>
      {selectedRows.length > 0 && (
        <button
          onClick={onOpen}
          type="button"
          className="mb-2 text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
          // disabled={isSubmitting}
          style={{
            color: "#fff",
            backgroundColor: `${exnetSpaceColor(milieu)}`,
          }}
        >
          Facturer
        </button>
      )}

      <FacturationModal
        isOpen={isOpen}
        onClose={onClose}
        shippingIds={selectedShippingIds}
      />

      <div className="mb-5 overflow-auto ">
        <div className="inline-block min-w-full py-2 align-middle ">
          <table className="w-full divide-y divide-gray-300">
            <thead className={"bg-[#ECECEC]"}>
              <tr className={" text-black "}>
                {checkboxVisible && allowCheckBoxToggler && (
                  <th className="mr-2">
                    <input
                      type="checkbox"
                      checked={allSelected}
                      onChange={toggleAllSelection}
                    />
                  </th>
                )}

                {headers.map(({ label, cle }, index) => (
                  <th
                    onClick={() => {
                      setfilterBy(cle || "");
                      setfilterOrder(filterOrder === "desc" ? "asc" : "desc");
                    }}
                    key={cle}
                    scope="col"
                    className="py-3.5 pl-4 pr-3  text-base font-normalsm:pl-0 text-center hover:cursor-pointer "
                  >
                    <div className={"flex items-center gap-1"}>
                      {" "}
                      {label?.toUpperCase()}
                      {filterOrder === "asc" ? (
                        <ArrowUpIcon
                          className={clsx(
                            {
                              "text-paris": isAuth?.spaceName === "paris",
                              "text-medical": isAuth?.spaceName === "medical",
                              "text-service": isAuth?.spaceName === "service",
                              "text-admin": isAuth?.spaceName === "admin",
                            },
                            "w-3 h-3"
                          )}
                        />
                      ) : (
                        <ArrowDownIcon
                          className={clsx(
                            {
                              "text-paris": isAuth?.spaceName === "paris",
                              "text-medical": isAuth?.spaceName === "medical",
                              "text-service": isAuth?.spaceName === "service",
                              "text-admin": isAuth?.spaceName === "admin",
                            },
                            "w-3 h-3"
                          )}
                        />
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {isLoading ? (
                <>
                  <tr>
                    <td colSpan={headers.length}>
                      <LoaderTableau />
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  {sortedArray.length > 0 ? (
                    <>
                      {sortedArray.map((item, index) => (
                        <TableRowComponent
                          allowCheckBoxToggler={allowCheckBoxToggler}
                          item={item}
                          key={item.id}
                          onAttributionSelect={onAttributionSelect}
                          checkboxVisible={checkboxVisible}
                          onSelect={onSelect}
                          onDispatchModalSelect={onDispatchModalSelect}
                          onAddTrackingClick={onAddTrackingClick}
                          isChecked={selectedShippingIds.includes(item.id)}
                          handleCheckboxChange={handleCheckboxChange}
                          sortedArray={sortedArray}
                          shippingLink={getShippingUrl?.(item)}
                          linkTarget={linkTarget}
                          isHighlighted={isShippingInAvoir(item.id)}
                        >
                          <AttributionComponent
                            shipping_id={item.id}
                            type={exnetSpace}
                            drivers={driversList}
                            value={item?.driver_id}
                            isDisabled={
                              lastPath !== "en-attente" &&
                              lastPath !== "en-cours"
                                ? true
                                : false
                            }
                          />
                        </TableRowComponent>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={headers.length}>
                        <div classname={"py-4 rounded-lg bg-white "}>
                          <p
                            className={
                              "my-4 text-xl font-medium text-center text-black "
                            }
                          >
                            Pas de données actuellement !{" "}
                          </p>
                        </div>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>

      
    </div>
  );
}
