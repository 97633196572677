import React, { useEffect, useState } from "react";
import DetailTabsComponent from "./tabsComponent/DetailTabsComponent";
import FichiersTabsComponent from "./tabsComponent/FichiersTabsComponent";
import EmailTabsComponent from "./tabsComponent/EmailTabsComponent";
import PodTabsComponent from "./tabsComponent/PodTabsComponent";
import CommentaireTabsComponent from "./tabsComponent/CommentaireTabsComponent";
import FactureTabsComponent from "./tabsComponent/FactureTabsComponent";
import Head from "../HeadComponent/Head";
import ClientExpeditionDetailComponent from "./client/clientExpeditionDetailComponent";
import AddTrackingComponent from "../tracking/AddTrackingComponent";
import ExpeditionService from "../../services/expedition/ExpeditionService.js";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { refPrefixer, replaceLastValueInPath, urlSplit } from "../../utils/Functions/otherFunctions";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { clearExpedition, setExpedition } from "../../features/slices/expeditionSlice";
import Manifest from "./tabsComponent/Manifest";
import LoaderTableau from "../tableauComponent/LoaderTableau";
import { formatShippingRef } from "../../utils/index.js";
import ViewProtector from "../Utils/ViewProtector.jsx";
import useAuthorization from "../../hooks/useAuthorization.jsx";

function DetailsColisComponent({ expeditionId, expeditionSpace }) {
  const expeditionStore = useSelector(
    (state) => state.expedition.allExpedition
  );

  const expeditionDetails = useSelector(
    (state) => state.expedition.expeditions
  );

  const { identifiant } = useParams()

  const dispatch = useDispatch();
  const [isLoding, setIsLoding] = useState(false);
  const [role, setRole] = useState("");
  const [expeditionData, setExpeditionData] = useState({});
  const [expeditionList, setexpeditionList] = useState([]);
  const { pathname } = useLocation();
  const [search] = useSearchParams()
  const navigate = useNavigate();
  const { exnetSpace } = urlSplit()


  /*   useEffect(() => {
      const isAvoir = search.get("isAvoir");
      console.log({ pathname, search, isAvoir })
      if (isAvoir) {
        //alert("A")
        const a = document.createElement("a");
        a.href = `${pathname}#tabsFacturation`;
  
        document.body.appendChild(a);
        a.click();
  
      }
    }, [pathname, search]) */



  const findPreviousAndNextElement = (id) => {
    const index = expeditionStore.findIndex((element) => element.ref === id);

    if (index === -1) {
      return null; // ID non trouvé dans le tableau
    }

    const previousIndex = index > 0 ? index - 1 : null;
    const nextIndex = index < expeditionStore.length - 1 ? index + 1 : null;

    const previousElement = previousIndex !== null ? expeditionStore[previousIndex].ref : null;
    const nextElement = nextIndex !== null ? expeditionStore[nextIndex].ref : null;

    return { previousElement, nextElement };
  }


  const handleNext = () => {

    let result = findPreviousAndNextElement(parseInt(expeditionId))
    if (result?.nextElement) {
      navigate(replaceLastValueInPath(pathname, result?.nextElement));
    } else {
      navigate(replaceLastValueInPath(pathname, expeditionId));
    }

  };

  const handlePrevious = () => {
    let result = findPreviousAndNextElement(parseInt(expeditionId))
    if (result?.previousElement) {
      navigate(replaceLastValueInPath(pathname, result?.previousElement));
    } else {
      navigate(replaceLastValueInPath(pathname, expeditionId));
    }
  };


  const ref = expeditionId;
  const [suffixeIndex, setSuffixeIndex] = useState("");
  const [title, setTitle] = useState(`Détail ${refPrefixer(exnetSpace)}${expeditionId}`);
  const expeditionRef = expeditionDetails?.billing_center ? formatShippingRef(expeditionDetails.ref, expeditionDetails.billing_center.type) : "-"

  function changeTitle(titleChange) {
    setTitle(`${titleChange} ${refPrefixer(exnetSpace)}${expeditionId}`);
  }


  const showFactureError = (e) => {
    e.preventDefault();
    toast.error("Votre expédition est toujours en cours d'expédition", {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };



  useEffect(() => {
    setIsLoding(true)
    let element = document.getElementById("bloquer");

    if (element) {
      if (expeditionDetails.pod?.length > 0 && !expeditionDetails.annulee) {
        element?.removeAttribute("disabled");
      } else {
        element?.setAttribute("disabled", true);
      }
    }

    let determinant =
      expeditionSpace === "paris"
        ? "EX"
        : expeditionSpace === "medical"
          ? "EM"
          : "ES";
    setSuffixeIndex(determinant);
    setRole(window.location.pathname.split("/")[1]);
    document.getElementById('fistLink').click();
    changeTitle('Detail')


    setTimeout(() => {
      document.querySelector("#progrss-wizard ul")?.removeAttribute("style");
    }, 500);


  }, []);

  useEffect(() => {
    if (pathname && pathname.indexOf(expeditionId)) {
      if (expeditionId === identifiant) {
        ExpeditionService.getExpeditionDetails({ ref: expeditionId }).then(
          (data) => {
            const { data: expedition } = data.data;
            dispatch(setExpedition(expedition));
            setExpeditionData({ ...data.data.data });
            setIsLoding(false)
          },
          (error) => {
            console.log(error);
          }
        );
      }
    }
  }, [expeditionId])


  const { isShippingTabAllowed } = useAuthorization()


  return (
    <>
      <Head>
        <title>EXNET | {title} </title>
      </Head>
      {role === "client" ? (
        <ClientExpeditionDetailComponent />
      ) : (
        <>
          <div className="card-box">
            <div id="progrss-wizard" className="twitter-bs-wizard">
              <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                <li className="nav-item">
                  <a
                    href={'#'}
                    className="nav-link"
                    onClick={handlePrevious}
                  >
                    <div
                      className="step-icon"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="précédent"
                    >
                      <i className="fas fa-arrow-left " />
                    </div>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    id={"fistLink"}
                    href="#tabsDetail"
                    className="nav-link active"
                    data-bs-toggle="tab"
                    onClick={() => changeTitle("Details")}
                  >
                    <div
                      className="step-icon"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Details Expédition"
                    >
                      <i className="far fa-newspaper " />
                    </div>
                  </a>
                </li>
                {window.location.pathname.split("/")[4] !== "chauffeur" ? (
                  <li className="nav-item">
                    <a
                      href="#tabsFichiers"
                      className="nav-link"
                      data-bs-toggle="tab"
                      onClick={() => changeTitle("Fichiers")}
                    >
                      <div
                        className="step-icon"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Fichiers"
                      >
                        <i className="fas fa-file" />
                      </div>
                    </a>
                  </li>
                ) : (
                  ""
                )}
                {window.location.pathname.split("/")[4] !== "chauffeur" ? (
                  <li className="nav-item">
                    <a
                      href="#tabsManifest"
                      className="nav-link"
                      data-bs-toggle="tab"
                      onClick={() => changeTitle("Manifest")}
                    >
                      <div
                        className="step-icon"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Manifest"
                      >
                        <i class="fas fa-solid fa-vector-square"></i>
                      </div>
                    </a>
                  </li>
                ) : (
                  ""
                )}
                {window.location.pathname.split("/")[4] !== "chauffeur" ? (
                  <li className="nav-item">
                    <a
                      href="#tabsEmail"
                      className="nav-link"
                      data-bs-toggle="tab"
                      onClick={() => changeTitle("Mails")}
                    >
                      <div
                        className="step-icon"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Mails"
                      >
                        <i className="fas fa-envelope " />
                      </div>
                    </a>
                  </li>
                ) : (
                  ""
                )}
                <li className="nav-item">
                  <a
                    href="#tabsTracking"
                    className="nav-link"
                    data-bs-toggle="tab"
                    onClick={() => changeTitle("Trackings")}
                  >
                    <div
                      className="step-icon"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Trackings"
                    >
                      <i className="far fa-map " />
                    </div>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#tabsPod"
                    className="nav-link"
                    data-bs-toggle="tab"
                    onClick={() => changeTitle("Pod")}
                  >
                    <div
                      className="step-icon"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Pod"
                    >
                      <i className="fas fa-map-pin" />
                    </div>
                  </a>
                </li>
                {window.location.pathname.split("/")[4] !== "chauffeur" ? (
                  <li className="nav-item">
                    <a
                      href="#tabsCommentaire"
                      className="nav-link"
                      data-bs-toggle="tab"
                      onClick={() => changeTitle("Commentaires")}
                    >
                      <div
                        className="step-icon"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Commentaires"
                      >
                        <i className="fas fa-comment " />
                      </div>
                    </a>
                  </li>
                ) : (
                  ""
                )}
                {window.location.pathname.split("/")[4] !== "chauffeur" ? (
                  <li className="nav-item">
                    <a
                      disabled={
                        expeditionDetails?.pod?.length > 0 &&
                          expeditionDetails?.annulee === 0
                          ? false
                          : true
                      }
                      href={`${expeditionDetails?.pod?.length > 0 &&
                        expeditionDetails?.annulee == 0
                        ? "#tabsFacturation"
                        : ""
                        }`}
                      className="nav-link"
                      data-bs-toggle="tab"
                      onClick={(e) => {
                        if (
                          expeditionDetails?.pod?.length > 0 &&
                          expeditionDetails.annulee == 0
                        ) {
                          changeTitle("Facturations");
                        } else {
                          showFactureError(e);
                        }
                      }}
                    >
                      <div
                        className="step-icon"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Facturations"
                      >
                        <i className="fas fa-euro-sign" />
                      </div>
                    </a>
                  </li>
                ) : (
                  ""
                )}
                <li className="nav-item">
                  <a
                    href={"#"}
                    className="nav-link"
                    onClick={handleNext}

                  >
                    <div
                      className="step-icon"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Suivant"
                    >
                      <i className="fas fa-arrow-right " />
                    </div>
                  </a>
                </li>
              </ul>
              <div id="bar" className="mt-4 progress" style={{ height: "30px" }}>
                <span
                  style={{
                    fontSize: "20px",
                    textAlign: "center",
                    display: "block",
                    width: "100%",
                  }}
                >
                  {/* Détails {expeditionRef} */}
                  {title}
                </span>
                <div className="progress-bar bg-success progress-bar-striped progress-bar-animated"></div>
              </div>
              {isLoding ? <>
                <LoaderTableau />
              </> :
                <> {(expeditionData.id) ? (
                  <div className="tab-content twitter-bs-wizard-tab-content">
                    <div className="tab-pane active" id="tabsDetail">
                      {isShippingTabAllowed.details && <DetailTabsComponent
                        expedition={expeditionData}
                        setExpeditionData={setExpeditionData}
                      />}
                    </div>

                    {isShippingTabAllowed.fichiers && <div className="tab-pane" id="tabsFichiers">
                      <FichiersTabsComponent expedition={expeditionData} />
                    </div>}

                    {isShippingTabAllowed.mails && <div className="tab-pane" id="tabsEmail">
                      <EmailTabsComponent expedition={expeditionData} />
                    </div>}

                    {isShippingTabAllowed.manifest && <div className="tab-pane" id="tabsManifest">
                      <Manifest expedition={expeditionData} />
                    </div>}

                    {isShippingTabAllowed.tracking && <div className="tab-pane" id="tabsTracking">
                      <AddTrackingComponent expedition={expeditionData} setExpeditionData={setExpeditionData} />
                    </div>}

                    {isShippingTabAllowed.pod && <div className="tab-pane" id="tabsPod">
                      <PodTabsComponent shipping_id={expeditionData.id} />
                    </div>}

                    {isShippingTabAllowed.comments && <div className="tab-pane" id="tabsCommentaire">
                      <CommentaireTabsComponent expedition={expeditionData} />
                    </div>}

                    {isShippingTabAllowed.facturations && <div className="tab-pane" id="tabsFacturation">
                      <FactureTabsComponent />
                    </div>}
                  </div>
                ) : (
                  <LoaderTableau />
                )}</>}

            </div>
          </div>
        </>

      )}
    </>
  );
}

export default DetailsColisComponent;
