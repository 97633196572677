import React, { useCallback, useState } from 'react'
import useDisclosure from '../../../hooks/useDisclosure';
//import Form from './Form';
import useAdminLists from '../../../hooks/useAdminLists';
import { formatDefaultDate } from '../../../utils/moment';
import { TableLoader } from '../../Utils/Loader';
import Form from './Form';
import DeleteConfirm from '../../Utils/DeleteConfirm';
import vehiculeService from '../../../services/vehicule/vehiculeService';
import { SwitchComponent } from '../../Utils/Index';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import PaginationComponent from '../../paginationComponent/PaginationComponent';
import useTableFilter from '../../../hooks/useTableFilter';
import { TbArrowsUpDown } from 'react-icons/tb';
import FacturationCodeService from "../../../services/facturationCode/service";
import { urlSplit } from '../../../utils/Functions/otherFunctions';
import { ArrowsUpDownIcon, PlusIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useAppContext } from '../../../context';

export default function FacturationsCodeView() {
    const { exnetSpace } = urlSplit();
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { isAuth, setIsAuth } = useAppContext();

    const {
        isOpen: isDeleteConfirmOpen,
        onClose: onDeleteConfirmClose,
        onOpen: onDeleteConfirmOpen
    } = useDisclosure();

    const type = "facturation-codes"

    const { adminData, metadata, setPage, setPerPage, isLoading, onSubmit, onDelete } = useAdminLists({ type });
    const [selectedItem, setSelectedItem] = useState(null)
    const [search, setSearch] = useState("")

    const filteredLists = adminData.data.filter(item => {
        const matchingLabel = item.label.includes(search)
        const matchingDescription = item.description.includes(search)
        return matchingLabel || matchingDescription
    })

    const onItemSelected = (item, action) => {
        setSelectedItem(item)
        if (action === "edit") {
            onOpen()
        } else {
            onDeleteConfirmOpen()
        }
    }


    const handleDelete = useCallback(async (item, index) => {
        try {
            Swal.fire({
                title: "Êtes-vous sûr de vouloir supprimer ce fichier ?",
                text: "Cette action est irréversible !",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: exnetSpace === 'paris' ? "#ba9964" : (exnetSpace === 'service' ? "#098721" : (exnetSpace === 'medical' ? "#3A8EBA" : "#222")),
                confirmButtonText: "Oui, supprimer",
                cancelButtonText: "Annuler",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    //delete result

                    const { data } = await FacturationCodeService.delete({ id: item.id })
                    if (data.success) {
                        onDelete(item)
                    }

                } else {
                    /* Swal.fire(
                        "Annuler",
                        "Suppression annulée avec succès",
                        "error",
                        500
                    ); */
                }
            })
        } catch (error) {
            console.error(error)
        }
    }, [onDelete])


    return (
        <div>
            <div className="flex items-center justify-between">
                <div>
                    <h4 className="uppercase page-title">Liste des codes de facturations</h4>
                    <div className="min-w-[350px]">
                        <input
                            className="w-full px-4 py-2 mb-3 bg-white border border-gray-500 rounded-lg outline-none form-control"
                            type="search"
                            placeholder="Recherche"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                </div>
                <div
                    className="float-right text-white bg-black btn btn-rounded"
                    onClick={onOpen}
                >
                    <PlusIcon className={"h-4 w-4 m-3 text-xl font-bold "} />
                </div>
            </div>

            <div className="py-3">
                {adminData.total ?
                    <PaginationComponent
                        page={metadata.page}
                        handlePageClick={setPage}
                        totalItems={adminData.total}
                        perPageItems={metadata.perPage}
                        setnumberPerPage={setPerPage}
                    /> : null}
            </div>

            <Lists data={filteredLists} onSelect={onItemSelected} isLoading={isLoading} onDelete={handleDelete} />
            <div className="py-3">
                {adminData.total ?
                    <PaginationComponent
                        page={metadata.page}
                        element={'pagination'}
                        handlePageClick={setPage}
                        totalItems={adminData.total}
                        perPageItems={metadata.perPage}
                        setnumberPerPage={setPerPage}
                    /> : null}
            </div>
            <Form
                isOpen={isOpen}
                onClose={() => {
                    onClose()
                    setSelectedItem(null)
                }}
                onSubmit={onSubmit}
                selectedData={selectedItem}
                count={adminData.data.length}
            />

            {selectedItem && <DeleteConfirm
                id={selectedItem.id}
                isOpen={isDeleteConfirmOpen}
                onClose={onDeleteConfirmClose}
                onDelete={() => {
                    onDelete()
                    onDeleteConfirmClose()
                }}
                deleteRequest={() => vehiculeService.deleteVehicule({ id: selectedItem.id })}
            />}
        </div>
    )
}


const Lists = ({ data, onSelect, isLoading, onDelete }) => {
    const { results, onTableHeaderClick } = useTableFilter({ items: data });
    const { isAuth, setIsAuth } = useAppContext();
    const HEADERS = [
        { label: 'ID', key: 'id' },
        { label: 'Libellé', key: 'label' },
        { label: 'Description', key: 'description' },
        { label: 'Montant', key: 'montant' },
        { label: 'Fuel HT', key: 'fuel' },
        { label: 'TVA', key: 'tva' },
        { label: 'Ajouté le', key: 'created_at' },
        { label: 'Action', key: null },
    ];

    return (
        <>
            <div className="mb-5 overflow-auto ">
                <div className="inline-block min-w-full py-2 align-middle ">
                    <table className="w-full divide-y divide-gray-300">
                        <thead className={"bg-[#ECECEC]"}>
                            <tr className={" text-black "}>
                                {HEADERS.map((item, index) => (
                                    <th
                                        onClick={() => onTableHeaderClick(item.key)}
                                        key={item}
                                        scope="col"
                                        className="py-3.5 pl-4 pr-3  text-base font-normalsm:pl-0 text-center hover:cursor-pointer "
                                    >
                                        <div className={"flex items-center gap-1"}>
                                            {" "}
                                            {item?.label.toUpperCase()}
                                            {item.key && <span>
                                                <ArrowsUpDownIcon className={clsx(
                                                    {
                                                        "text-paris": isAuth?.spaceName === "paris",
                                                        "text-medical": isAuth?.spaceName === "medical",
                                                        "text-service": isAuth?.spaceName === "service",
                                                        "text-admin": isAuth?.spaceName === "admin",
                                                    },
                                                    "w-3 h-3"
                                                )} />
                                            </span>}

                                        </div>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            {isLoading ? (
                                <>
                                    <tr>
                                        <td colSpan={HEADERS.length}>
                                            <TableLoader />
                                        </td>
                                    </tr>
                                </>
                            ) : (
                                <>
                                    {results.length > 0 ? (
                                        <>
                                            {results.map((item, i) => {
                                                return (
                                                    <tr key={i} className="py-3">
                                                        <td className={'cursor-pointer'}
                                                            onClick={() => {
                                                                onSelect(item, "edit")
                                                            }}
                                                        >{item.id}</td>
                                                        <td className={'cursor-pointer'}
                                                            onClick={() => {
                                                                onSelect(item, "edit")
                                                            }}
                                                        >{item.label}</td>
                                                        <td className={'cursor-pointer'}
                                                            onClick={() => {
                                                                onSelect(item, "edit")
                                                            }}
                                                        >{item.description}</td>
                                                        <td className={'cursor-pointer'}
                                                            onClick={() => {
                                                                onSelect(item, "edit")
                                                            }}
                                                        >{item.montant}</td>
                                                        <td className={'cursor-pointer'}
                                                            onClick={() => {
                                                                onSelect(item, "edit")
                                                            }}
                                                        >{item.fueldata?.value}</td>
                                                        <td className={'cursor-pointer'}
                                                            onClick={() => {
                                                                onSelect(item, "edit")
                                                            }}
                                                        >
                                                            <SwitchComponent
                                                                label="for_variation"
                                                                checked={Boolean(item.tva === 1)}
                                                                disabled
                                                            />
                                                        </td>

                                                        <td className={'cursor-pointer'}
                                                            onClick={() => {
                                                                onSelect(item, "edit")
                                                            }}>
                                                            {formatDefaultDate(item.created_at)}
                                                        </td>

                                                        <td>
                                                            <div className="flex items-center">
                                                                <div
                                                                    className={`text-red-500 cursor-pointer text-[14px]`}
                                                                    onClick={() => {
                                                                        //onDeleteConfirmOpen()
                                                                        onDelete(item, i)
                                                                    }}
                                                                    title="Supprimer"
                                                                >
                                                                    <i className="fas fa-trash"></i>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </>
                                    ) : (
                                        <tr>
                                            <td colSpan={HEADERS.length}>
                                                <div classname={"py-4 rounded-lg bg-white "}>
                                                    <p
                                                        className={
                                                            "my-4 text-xl font-medium text-center text-black "
                                                        }
                                                    >
                                                        Pas de données actuellement !{" "}
                                                    </p>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>

    )
}