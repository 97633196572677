import React, { useCallback, useMemo, useState } from 'react'
import useDisclosure from '../../hooks/useDisclosure';
import { ClientModal } from './CreateForm';
import useAdminLists from '../../hooks/useAdminLists';
import { formatDefaultDate } from '../../utils/moment';
import { formatInterface } from '../../utils';
import { TableLoader } from '../Utils/Loader';
import GeneralStateComponent from '../generalStateComponent/GeneralStateComponent';
import { SwitchComponent } from '../Utils/Index';
import { getCountryNameOrCode } from '../../utils/const/countriesConstant';
import clientService from '../../services/client/clientService';
import { toast } from 'react-toastify';
import PaginationComponent from '../paginationComponent/PaginationComponent';
import { trierTableau } from '../../utils/Functions/tableFilter';
import useTableFilter from '../../hooks/useTableFilter';
import { TbArrowsUpDown } from 'react-icons/tb';
import { PlusIcon } from '@heroicons/react/24/outline';

export default function ClientsView() {
    const { isOpen, onClose, onOpen } = useDisclosure();

    const {
        isOpen: isDeleteConfirmOpen,
        onClose: onDeleteConfirmClose,
        onOpen: onDeleteConfirmOpen
    } = useDisclosure();

    const type = "clients"

    const { adminData, metadata, setPage, setPerPage, isLoading, onSubmit, search, setSearch } = useAdminLists({ type });
    const [selectedItem, setSelectedItem] = useState(null)

    const onItemSelected = (item, action) => {
        setSelectedItem(item)
        if (action === "edit") {
            onOpen()
        } else {
            onDeleteConfirmOpen()
        }
    }

    const toggleActif = useCallback(async (item, value) => {
        try {
            //onSubmit({ ...item, actif: value ? 1 : 0 })
            const request = value ? clientService.activerClientById : clientService.desactiverClientById
            const { data } = await request({ id: item.id });
            if (data.success) {
                onSubmit({ ...item, actif: value ? 1 : 0 })
            } else {
                toast.error(`Une erreur est sruvenue`, {
                    position: "bottom-center",
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error(error)
            toast.error(`Une erreur est sruvenue`, {
                position: "bottom-center",
                autoClose: 4000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }, [onSubmit])

    return (
        <div>
            {/*  <GeneralStateComponent dataType={type} /> */}
            <div className="flex items-center justify-between">
                <div>
                    <h4 className="mb-1 uppercase page-title">Liste des clients</h4>
                    <div className="min-w-[350px]">
                        <input
                            className="w-full px-4 py-2 mb-3 bg-white border border-gray-500 rounded-lg outline-none form-control"
                            type="search"
                            placeholder="Recherche"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                </div>
                <div
                    className="float-right text-white bg-black btn btn-rounded"
                    onClick={onOpen}
                >
                    <PlusIcon  className={"h-4 w-4 m-3 text-xl font-bold "}/>
                </div>
            </div>

            <div className="py-3">
                {adminData.total ?
                    <PaginationComponent
                        page={metadata.page}
                        handlePageClick={setPage}
                        totalItems={adminData.total}
                        perPageItems={metadata.perPage}
                        setnumberPerPage={setPerPage}
                    /> : null}
            </div>

            <Lists
                data={adminData.data}
                onSelect={onItemSelected}
                isLoading={isLoading}
                onToggleActive={toggleActif}

            />
            <div className="py-3">
                {adminData.total ?
                    <PaginationComponent
                        page={metadata.page}
                        element='pagination'
                        handlePageClick={setPage}
                        totalItems={adminData.total}
                        perPageItems={metadata.perPage}
                        setnumberPerPage={setPerPage}
                    /> : null}
            </div>

            <ClientModal isOpen={isOpen}
                onClose={() => {
                    onClose()
                    setSelectedItem(null)
                }} onSubmit={(value) => {
                    onSubmit(value)
                    onClose()
                }} selectedData={selectedItem} />

        </div>
    )
}


const Lists = ({ data, onSelect, isLoading, onToggleActive }) => {

    const { results, onTableHeaderClick } = useTableFilter({ items: data })

    const HEADERS = [
        { label: 'ID', key: 'id' },
        { label: 'Nom du client', key: 'fullname' },
        { label: 'Type', key: 'type' },
        { label: 'Pays', key: 'country' },
        { label: 'Adresse', key: 'address' },
        { label: 'Ville', key: 'city' },
        { label: 'Téléphone', key: 'telephone1' },
        { label: 'Email de facturation', key: 'email_facturation' },
        // { label: 'TVA', key: 'text-left' },
        { label: 'Date de Création', key: 'created_at' },
        { label: 'Actif', key: null },
        // { label: 'Action', key: 'text-left' },
    ];

    return (
        <div className="relative overflow-x-auto">
            <div className="bg-white card-box">
                <div className="card-block">
                    {/*                     <h4 className="card-title">Basic Table</h4>*/}
                    <div className="table-responsive">
                        <table className="table mb-0">
                            <thead>
                                <tr className='uppercase'>
                                    {HEADERS.map((h, i) => (
                                        <th key={i} className="text-left cursor-pointer" onClick={() => {
                                            onTableHeaderClick(h.key)
                                        }}>
                                            <div className="flex items-center">
                                                <span className='mr-2'>
                                                    {h.label}
                                                </span>
                                                {h.key && <span>
                                                    <TbArrowsUpDown />
                                                </span>}
                                            </div>

                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {results.length > 0 && (
                                    results.map((item, i) => (
                                        <tr key={i} className="py-3">
                                            <td
                                                className={'cursor-pointer'}
                                                onClick={() => {
                                                    onSelect(item, "edit")
                                                }}
                                            >{item.id}</td>
                                            <td className={'cursor-pointer'}
                                                onClick={() => {
                                                    onSelect(item, "edit")
                                                }}
                                            >{item.fullname}</td>
                                            <td className={'cursor-pointer'}
                                                onClick={() => {
                                                    onSelect(item, "edit")
                                                }}
                                            >
                                                <div className="flex items-center">
                                                    {item.type.split(",")?.map(type => (
                                                        <div className={`${type} text-center rounded-sm px-1 mr-2`}>
                                                            {formatInterface(type)}
                                                        </div>
                                                    ))}
                                                </div>
                                            </td>
                                            <td className={'cursor-pointer'}
                                                onClick={() => {
                                                    onSelect(item, "edit")
                                                }}
                                            >
                                                <div className="flex flex-wrap items-center">
                                                    <img
                                                        src={`https://flagcdn.com/16x12/${getCountryNameOrCode(getCountryNameOrCode(item.country), true)}.png`}
                                                        srcSet={`https://flagcdn.com/32x24/${getCountryNameOrCode(getCountryNameOrCode(item.country), true)}.png 2x,
                                    https://flagcdn.com/48x36/${getCountryNameOrCode(getCountryNameOrCode(item.country), true)}.png 3x`}
                                                        width="16"
                                                        height="12"
                                                        alt={getCountryNameOrCode(
                                                            getCountryNameOrCode(item.country),
                                                            true
                                                        )} />
                                                    <div className='ml-1'>
                                                        {getCountryNameOrCode(item.country)}
                                                    </div>
                                                </div>
                                            </td>
                                            <td className={'cursor-pointer'}
                                                onClick={() => {
                                                    onSelect(item, "edit")
                                                }}
                                            >{item.address}</td>
                                            <td className={'cursor-pointer'}
                                                onClick={() => {
                                                    onSelect(item, "edit")
                                                }}
                                            >{item.city}</td>
                                            <td className={'cursor-pointer'}
                                                onClick={() => {
                                                    onSelect(item, "edit")
                                                }}
                                            >{item.telephone || item.telephone1}</td>
                                            <td className={'cursor-pointer'}
                                                onClick={() => {
                                                    onSelect(item, "edit")
                                                }}
                                            >{item.email_facturation || "-"}</td>
                                            {/* <td>{item.tva}</td> */}
                                            <td className={'cursor-pointer'}
                                                onClick={() => {
                                                    onSelect(item, "edit")
                                                }}
                                            >{formatDefaultDate(item.created_at)}</td>
                                            <td>
                                                <SwitchComponent
                                                    checked={Boolean(item.actif)}
                                                    onChange={(value) => {
                                                        onToggleActive(item, value)
                                                    }}
                                                    checkedColor="#098721"
                                                />
                                            </td>
                                            {/* <td>
                                                {Boolean(item.actif) ?
                                                    <div className="flex items-center">
                                                        <div
                                                            className="text-green-600 cursor-pointer text-[14px] mr-5"
                                                            onClick={() => {
                                                                onSelect(item, "edit")
                                                            }} title="Editer"
                                                        >
                                                            <i className="fas fa-edit"></i>
                                                        </div>
                                                    </div> : "-"}
                                            </td> */}
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                        {isLoading ? <TableLoader /> : results.length === 0 ? (
                            <h6 className="my-5 text-lg text-center">Aucune donnée</h6>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    )
}