
import parisImport from '../../../assets/img/svg/Paris-import.svg'
import parisExport from '../../../assets/img/svg/Paris-export.svg'
import pariscourse from '../../../assets/img/svg/Paris.svg'
import archiveLogo from '../../../assets/img/Icones/archive/paris.svg'
import factureLogo from '../../../assets/img/Icones/Facture/paris.svg'
import historiqueLogo from '../../../assets/img/Icones/historique/paris.svg'
import plusLogo from '../../../assets/img/Icones/plus/paris.svg'
import explotationLogo from '../../../assets/img/Icones/exploitation/paris.svg'
import dashLogo from '../../../assets/img/Icones/Dashboard/paris.svg'


export const opsParisMenu = [
    {
        isMultiple: true,
        tague: "dashboard",
        isTitle: false,
        libelle: "Dashboard",
        icon: <img alt={'fd'} src={dashLogo} className={` w-8 hover:text-black`} />,
        liens: [
            {
                libelle: "Expéditions en attente",
                lien: "/ops/paris/dash/en-attente",
                key: "shippingPending",
                tague: "dashboard-en-attente",
            },
            {
                libelle: "Expéditions en cours",
                lien: "/ops/paris/dash/en-cours",
                key: "shippingActive",
                tague: "dashboard-en-cours",
            },
            {
                libelle: "Expéditions livrées",
                lien: "/ops/paris/dash/livre",
                key: "shippingDelivered",
                tague: "dashboard-livre",
            },
            {
                libelle: "Expéditions à archiver",
                lien: "/ops/paris/dash/a-archivee",
                key: "shippingArchived",
                tague: "dashboard-a-archivee",
            },
            {
                libelle: "Expéditions facturées",
                lien: "/ops/paris/dash/facturee",
                key: "shippingFactured",
                tague: "dashboard-facturee",
            },
            {
                libelle: "Expéditions annulées",
                lien: "/ops/paris/dash/annulee",
                key: "shippingCancelled",
                tague: "dashboard-annulee",
            },
            {
                libelle: "Manifest",
                lien: "/ops/paris/dash/manifest",
                key: "shippingManifest",
                tague: "dashboard-manifest",
            }
        ],
    },
    {
        libelle: "Les courses",
        isTitle: true
    },
    {
        isMultiple: true,
        tague: "courses",
        isTitle: false,
        libelle: "Liste des courses",
        icon: <img alt={'fd'} src={pariscourse} className={` w-8 hover:text-black`} />,
        liens: [
            {
                libelle: "Courses en attente",
                lien: "/ops/paris/dash/courses-a-courses/en-attente",
                tague: "courses-en-attente",
            },
            {
                libelle: "Courses en cours",
                lien: "/ops/paris/dash/courses-a-courses/en-cours",
                tague: "courses-en-cours",
            },
            {
                libelle: "Courses livrées",
                lien: "/ops/paris/dash/courses-a-courses/livre",
                tague: "courses-livre",
            },
            {
                libelle: "Courses à archiver",
                lien: "/ops/paris/dash/courses-a-courses/a-archivee",
                tague: "courses-a-archivee",
            },
            {
                libelle: "Courses facturées",
                lien: "/ops/paris/dash/courses-a-courses/facturee",
                tague: "courses-facturee",
            },
            {
                libelle: "Courses annulées",
                lien: "/ops/paris/dash/courses-a-courses/annulee",
                tague: "courses-annulee",
            },
            {
                libelle: "Manifest",
                lien: "/ops/paris/dash/manifest",
                tague: "courses-manifest",
            },
        ],
    },
    {
        isMultiple: false,
        tague: "course-new",
        isTitle: false,
        libelle: "Nouvelle course",
        lien: "/ops/paris/dash/courses-a-courses/new",
        icon: <img alt={'fd'} src={plusLogo} className={` w-8 hover:text-black`} />,
    },
    {
        libelle: "Les exports",
        isTitle: true
    },
    {
        isMultiple: true,
        tague: "exports",
        isTitle: false,
        libelle: "Liste des exports",
        icon: <img alt={'fd'} src={parisExport} className={` w-8 hover:text-black`} />,
        liens: [
            {
                libelle: "Exports en attente",
                lien: "/ops/paris/dash/export/en-attente",
                tague: "exports-en-attente"
            },
            {
                libelle: "Exports en cours",
                lien: "/ops/paris/dash/export/en-cours",
                tague: "exports-en-cours"
            },
            {
                libelle: "Exports livrées",
                lien: "/ops/paris/dash/export/livre",
                tague: "exports-livre"
            },
            {
                libelle: "Exports à archiver",
                lien: "/ops/paris/dash/export/a-archivee",
                tague: "exports-a-archivee"
            },
            {
                libelle: "Exports facturées",
                lien: "/ops/paris/dash/export/facturee",
                tague: "exports-facturee"
            },
            {
                libelle: "Exports annulées",
                lien: "/ops/paris/dash/export/annulee",
                tague: "exports-annulee"
            },
            {
                libelle: "Manifest",
                lien: "/ops/paris/dash/manifest",
                tague: "exports-manifest"
            }
        ],
    },
    {
        isMultiple: false,
        tague: "export-new",
        isTitle: false,
        libelle: "Nouvel export",
        lien: "/ops/paris/dash/export/new",
        icon: <img alt={'fd'} src={plusLogo} className={` w-8 hover:text-black`} />,
    },
    {
        libelle: "Les imports",
        isTitle: true
    },
    {
        isMultiple: true,
        tague: "imports",
        isTitle: false,
        libelle: "Liste des imports",
        icon: <img alt={'fd'} src={parisImport} className={` w-8 hover:text-black`} />,
        liens: [
            {
                libelle: "Imports en attente",
                lien: "/ops/paris/dash/import/en-attente",
                tague: "imports-en-attente",
            },
            {
                libelle: "Imports en cours",
                lien: "/ops/paris/dash/import/en-cours",
                tague: "imports-en-cours",
            },
            {
                libelle: "Imports livrées",
                lien: "/ops/paris/dash/import/livre",
                tague: "imports-livre",
            },
            {
                libelle: "Imports à archiver",
                lien: "/ops/paris/dash/import/a-archivee",
                tague: "imports-a-archivee",
            },
            {
                libelle: "Imports facturées",
                lien: "/ops/paris/dash/import/facturee",
                tague: "imports-facturee",
            },
            {
                libelle: "Imports annulées",
                lien: "/ops/paris/dash/import/annulee",
                tague: "imports-annulee",
            },
            {
                libelle: "Manifest",
                lien: "/ops/paris/dash/manifest",
                tague: "imports-",
            }
        ],
    },
    {
        isMultiple: false,
        tague: "import-new",
        isTitle: false,
        libelle: "Nouvel import",
        lien: "/ops/paris/dash/import/new",
        icon: <img alt={'fd'} src={plusLogo} className={` w-8 hover:text-black`} />,
    },
    {
        libelle: "Gestion",
        isTitle: true
    },
    {
        isMultiple: true,
        tague: "factures",
        isTitle: false,
        libelle: "Factures",
        icon: <img alt={'fd'} src={factureLogo} className={` w-8 hover:text-black`} />,
        key: "facture",
        liens: [
            {
                libelle: "Liste des factures",
                lien: "/ops/paris/dash/factures/listes",
                tague: "factures-listes",
            },
            {
                libelle: "Bordereaux archivés",
                lien: "/ops/paris/dash/factures/bordereaux",
                tague: "factures-borderaux",
            },
            {
                libelle: "Extractions",
                lien: "/ops/paris/dash/factures/extractions",
                tague: "factures-extractions",
            },
            {
                libelle: "Liste globale",
                lien: "/ops/paris/dash/factures/liste-globale",
                tague: "factures-liste-globale",
            },
        ],
    },
    {
        isMultiple: false,
        tague: "archivee",
        isTitle: false,
        libelle: "Expéditions archivées",
        lien: "/ops/paris/dash/archivee",
        icon: <img alt={'fd'} src={archiveLogo} className={` w-8 hover:text-black`} />,
        key: "archived"
    },
    {
        isMultiple: false,
        tague: "exploitation",
        isTitle: false,
        // chauffeurs
        libelle: "Exploitation / dispatch",
        lien: "/exploitation/paris/auth",
        icon: <img alt={'fd'} src={explotationLogo} className={` w-8 hover:text-black`} />,
    },
    {
        isMultiple: false,
        tague: "historique",
        isTitle: false,
        libelle: "Le 24 Heures",
        lien: "/ops/paris/dash/history",
        icon: <img alt={'fd'} src={historiqueLogo} className={` w-8 hover:text-black`} />,
    },
]