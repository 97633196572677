import React, { useEffect, useState } from 'react';
import { TbArrowsUpDown } from 'react-icons/tb';
import { exnetSpaceColor, urlSplit } from '../../utils/Functions/otherFunctions';
import { trierTableau } from '../../utils/Functions/tableFilter';
import moment from 'moment';
import LoaderTableau from '../tableauComponent/LoaderTableau';

const headers = [
    {
        label: 'Expedition',
        filterKey: ''
    },
    {
        label: 'Date',
        filterKey: 'created_at'
    },
    {
        label: 'Action',
        filterKey: 'action'
    },
    {
        label: 'Utilisateur',
        filterKey: 'user.name'
    },

]

function List({ items = [], isLoading }) {
    const { exnetSpace } = urlSplit()
    const [filterBy, setfilterBy] = useState('ref');
    const [filterOrder, setfilterOrder] = useState('desc');




    const updateFilterListe2 = (filterKey) => {
        //console.log({ filterKey })
        if (filterKey) {
            setfilterBy(filterKey)
            let newOrderFilter = filterOrder === 'desc' ? 'asc' : 'desc'
            setfilterOrder(newOrderFilter)

        }
    }

    const newTable = trierTableau(items, filterBy, filterOrder)

    return (

        <div className="mb-5 overflow-auto ">
            <div className="inline-block min-w-full py-2 align-middle ">
                <table className="w-full divide-y divide-gray-300">
                    <thead className={"bg-[#ECECEC]"}>
                        <tr className={" text-black "}>


                            {headers.map((item, index) => (
                                <th key={index} onClick={() => updateFilterListe2(item.filterKey, filterOrder === 'desc' ? 'asc' : 'desc')} className="cursor-pointer">
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <span>
                                            {item.label.toUpperCase()}
                                        </span>

                                        <span>
                                            <TbArrowsUpDown color={`${exnetSpaceColor(exnetSpace)}`} />
                                        </span>


                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {isLoading ? (
                            <>
                                <tr>
                                    <td colSpan={headers.length}>
                                        <LoaderTableau />
                                    </td>
                                </tr>
                            </>
                        ) : (
                            <>
                                {newTable.length > 0 ? (
                                    <>
                                        {newTable.map((item, index) => (
                                            <tr key={index}>
                                                <td>
                                                    {item?.shipping?.ref || '-'}
                                                </td>
                                                <td>
                                                    {moment(item.created_at).format("DD-MM-YYYY")}
                                                </td>
                                                <td>
                                                    {item.action}
                                                </td>
                                                <td>
                                                    {item?.user?.name}
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                ) : (
                                    <tr>
                                        <td colSpan={headers.length}>
                                            <div classname={"py-4 rounded-lg bg-white "}>
                                                <p
                                                    className={
                                                        "my-4 text-xl font-medium text-center text-black "
                                                    }
                                                >
                                                    Pas de données actuellement !{" "}
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </>
                        )}
                    </tbody>
                </table>
            </div>
        </div>



    );
}

export default List;