import React, { useCallback, useState } from 'react'
import useDisclosure from '../../../hooks/useDisclosure';
//import Form from './Form';
import useAdminLists from '../../../hooks/useAdminLists';
import { formatDefaultDate } from '../../../utils/moment';
import { TableLoader } from '../../Utils/Loader';
import Form from './Form';
import DeleteConfirm from '../../Utils/DeleteConfirm';
import vehiculeService from '../../../services/vehicule/vehiculeService';
import { SwitchComponent } from '../../Utils/Index';
import { toast } from 'react-toastify';
import fuelService from '../../../services/fuel/fuelService';
import PaginationComponent from '../../paginationComponent/PaginationComponent';
import useTableFilter from '../../../hooks/useTableFilter';
import { TbArrowsUpDown } from 'react-icons/tb';
import { PlusIcon } from '@heroicons/react/24/outline';

export default function FuelsView() {
    const { isOpen, onClose, onOpen } = useDisclosure();

    const {
        isOpen: isDeleteConfirmOpen,
        onClose: onDeleteConfirmClose,
        onOpen: onDeleteConfirmOpen
    } = useDisclosure();

    const type = "fuels"

    const { adminData, metadata, setPage, setPerPage, isLoading, onSubmit, onDelete } = useAdminLists({ type });
    const [selectedItem, setSelectedItem] = useState(null)

    const onItemSelected = (item, action) => {
        setSelectedItem(item)
        if (action === "edit") {
            onOpen()
        } else {
            onDeleteConfirmOpen()
        }
    }

    const toggleActif = useCallback(async (item, value) => {
        try {
            //onSubmit({ ...item, actif: value ? 1 : 0 })
            const { data } = await fuelService.toggleActivation({ id: item.id })
            console.log({ data })
            if (data.success) {
                onSubmit({ ...item, is_actif: value ? 1 : 0 });
            } else {
                toast.error(`Une erreur est survenue`, {
                    position: "bottom-center",
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error(error);
            toast.error(`Une erreur est survenue`, {
                position: "bottom-center",
                autoClose: 4000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }, [onSubmit]);


    return (
        <div>
            <div className="flex items-center justify-between">
                <h4 className="uppercase page-title">Liste des fuels</h4>
                <div
                    className="float-right text-white bg-black btn btn-rounded"
                    onClick={onOpen}
                >
                   <PlusIcon  className={"h-4 w-4 m-3 text-xl font-bold "}/>
                </div>
            </div>

            <div className="py-3">
                {adminData.total ?
                    <PaginationComponent
                        page={metadata.page}
                        handlePageClick={setPage}
                        totalItems={adminData.total}
                        perPageItems={metadata.perPage}
                        setnumberPerPage={setPerPage}
                    /> : null}
            </div>

            <Lists data={adminData.data} onSelect={onItemSelected} isLoading={isLoading} onToggleActive={toggleActif} />
            <div className="py-3">
                {adminData.total ?
                    <PaginationComponent
                        page={metadata.page}
                        element={'pagination'}
                        handlePageClick={setPage}
                        totalItems={adminData.total}
                        perPageItems={metadata.perPage}
                        setnumberPerPage={setPerPage}
                    /> : null}
            </div>
            <Form
                isOpen={isOpen}
                onClose={() => {
                    onClose()
                    setSelectedItem(null)
                }}
                onSubmit={onSubmit}
                selectedData={selectedItem}
                count={adminData.data.length}
            />

            {selectedItem && <DeleteConfirm
                id={selectedItem.id}
                isOpen={isDeleteConfirmOpen}
                onClose={onDeleteConfirmClose}
                onDelete={() => {
                    onDelete()
                    onDeleteConfirmClose()
                }}
                deleteRequest={() => vehiculeService.deleteVehicule({ id: selectedItem.id })}
            />}
        </div>
    )
}


const Lists = ({ data, onSelect, isLoading, onToggleActive }) => {
    const { results, onTableHeaderClick } = useTableFilter({ items: data });

    const HEADERS = [
        { label: 'ID', key: 'id' },
        { label: 'Valeur', key: 'value' },
        { label: 'Pour variation', key: 'for_variation', transform: value => (value === 1 ? 'Oui' : 'Non') },
        { label: 'Date de création', key: 'created_at' },
        { label: 'Actif', key: null, isSwitch: true },
    ];
    return (
        <div className="relative overflow-x-auto">
            <div className="bg-white card-box">
                <div className="card-block">
                    {/*                     <h4 className="card-title">Basic Table</h4>*/}
                    <div className="table-responsive">
                        <table className="table mb-0">
                            <thead>
                                <tr className='uppercase'>
                                    {HEADERS.map((h, i) => (
                                        <th key={i} className="text-left cursor-pointer" onClick={() => {
                                            onTableHeaderClick(h.key)
                                        }}>
                                            <div className="flex items-center">
                                                <span className='mr-2'>
                                                    {h.label}
                                                </span>
                                                {h.key && <span>
                                                    <TbArrowsUpDown />
                                                </span>}
                                            </div>

                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {results.length > 0 && (
                                    results.map((item, i) => {
                                        return (
                                            <tr key={i} className="py-3">
                                                <td
                                                    className={'cursor-pointer'}
                                                    onClick={() => {
                                                        onSelect(item, "edit")
                                                    }}
                                                >{item.id}</td>
                                                <td
                                                    className={'cursor-pointer'}
                                                    onClick={() => {
                                                        onSelect(item, "edit")
                                                    }}
                                                >{item.value ? `${item.value}%` : "-"}</td>
                                                <td
                                                    className={'cursor-pointer'}
                                                    onClick={() => {
                                                        onSelect(item, "edit")
                                                    }}
                                                >{item.for_variation === 1 ? "Oui" : "Non"}</td>
                                                <td
                                                    className={'cursor-pointer'}
                                                    onClick={() => {
                                                        onSelect(item, "edit")
                                                    }}
                                                >{formatDefaultDate(item.created_at)}</td>
                                                <td>
                                                    <SwitchComponent checked={Boolean(item.is_actif)}
                                                        onChange={(value) => {
                                                            onToggleActive(item, value);
                                                        }} />
                                                </td>
                                                {/*  <td>
                                                    <div className="flex items-center">
                                                        <div
                                                            className="text-green-600 cursor-pointer text-[14px] mr-5"
                                                            onClick={() => {
                                                                onSelect(item, "edit")
                                                            }} title="Editer"
                                                        >
                                                            <i className="fas fa-edit"></i>
                                                        </div>
                                                    </div>
                                                </td> */}
                                            </tr>
                                        )
                                    })
                                )}
                            </tbody>
                        </table>
                        {isLoading ? <TableLoader /> : results.length === 0 ? (
                            <h6 className="my-5 text-lg text-center">Aucune donnée</h6>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    )
}