import React, { useCallback, useState } from "react";
import useDisclosure from "../../../hooks/useDisclosure";
//import Form from './Form';
import useAdminLists from "../../../hooks/useAdminLists";
import { formatDefaultDate } from "../../../utils/moment";
import { TableLoader } from "../../Utils/Loader";
import { ActorModal } from "../../Users/UsersSimpleForm";
import { SwitchComponent } from "../../Utils/Index";
import { userService } from "../../../services/user/UserService";
import { toast } from "react-toastify";
import PaginationComponent from "../../paginationComponent/PaginationComponent";
import useTableFilter from "../../../hooks/useTableFilter";
import { TbArrowsUpDown } from "react-icons/tb";
import CarAttribution from "../Cars/CarAttribution";
import OptionsSelector from "../../Utils/Selectors/Index";
import { stateOptions } from "../../../utils/const/data";
import { PlusIcon } from "@heroicons/react/24/outline";

export const OpsView = () => {
  return <ActorView type="ops" title="Liste des opérationnels" />;
};

export const AdminView = () => {
  return <ActorView type="admin" title="Liste des administrateurs" />;
};

export const DriversView = () => {
  return <ActorView type="chauffeur" title="Liste des chauffeurs" />;
};

const ActorView = ({ type, title }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const {
    isOpen: isDeleteConfirmOpen,
    onClose: onDeleteConfirmClose,
    onOpen: onDeleteConfirmOpen,
  } = useDisclosure();

  const { adminData, metadata, setMetadata, setPage, setPerPage, isLoading, onSubmit, search, setSearch } = useAdminLists({ type });
  const [selectedItem, setSelectedItem] = useState(null);

  const onItemSelected = (item, action) => {
    setSelectedItem(item);
    if (action === "edit") {
      onOpen();
    } else {
      onDeleteConfirmOpen();
    }
  };

  const toggleActif = useCallback(async (item, value) => {
    try {
      //onSubmit({ ...item, actif: value ? 1 : 0 })
      const request = value ? userService.activer : userService.desactiver;
      const { data } = await request({ id: item.id });
      if (data.success) {
        onSubmit({ ...item, is_actif: value ? 1 : 0 });
      } else {
        toast.error(`Une erreur est survenue`, {
          position: "bottom-center",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.error(error);
      toast.error(`Une erreur est survenue`, {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [onSubmit]);

  const query = metadata.filter;

  const setFilterValue = (key, value) => {
    setMetadata(m => ({
      ...m,
      filter: {
        ...m.filter,
        [key]: value
      }
    }))
  }


  /*   const onCarAttributed = useCallback(
      (car) => {
  
      },
      [],
    ) */


  return (
    <div>
      <div className="flex items-center justify-between">
        <div>
          <h4 className="uppercase page-title">{title}</h4>
          <div className="flex items-center">
            <div className="w-full mr-4 md:w-1/4">
              <label
                for="types"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Recherche
              </label>
              <input
                className="w-full px-4 py-2 bg-white border border-gray-500 rounded-lg outline-none form-control"
                type="search"
                placeholder="Recherche"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div className="w-full mr-4 md:w-1/4">
              <OptionsSelector
                label={"Statut"}
                options={stateOptions}
                value={query.state}
                defaultValue="all"
                onChange={v => {
                  //console.log({ v })
                  setFilterValue("state", v.value)
                }}
              />
            </div>
          </div>

        </div>
        <div
          className="float-right text-white bg-black btn btn-rounded"
          onClick={onOpen}
        >
          <PlusIcon  className={"h-4 w-4 m-3 text-xl font-bold "}/>
        </div>
      </div>

      <div className="py-3">
        {adminData.total ?
          <PaginationComponent
            page={metadata.page}
            handlePageClick={setPage}
            totalItems={adminData.total}
            perPageItems={metadata.perPage}
            setnumberPerPage={setPerPage}
          /> : null}
      </div>

      <Lists
        data={adminData.data}
        onSelect={onItemSelected}
        isLoading={isLoading}
        onToggleActive={toggleActif}
        type={type}
      //onCarAttributed={onCarAttributed}
      />
      <div className="py-3">
        {adminData.total ?
          <PaginationComponent
          page={metadata.page}
            element="pagination"
            handlePageClick={setPage}
            totalItems={adminData.total}
            perPageItems={metadata.perPage}
            setnumberPerPage={setPerPage}
          /> : null}
      </div>

      <ActorModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setSelectedItem(null);
        }}
        onSubmit={onSubmit}
        selectedData={selectedItem}
        type={type}
      />
    </div>
  );
};

const Lists = ({ type, data, onSelect, /* onCarAttributed, */ isLoading, onToggleActive }) => {
  const { results, onTableHeaderClick } = useTableFilter({ items: data });

  const isDriverView = type === "chauffeur"
  const { adminData: carsData } = useAdminLists({ type: "vehicules", loop: isDriverView });


  const HEADERS = !isDriverView ? [
    { label: 'ID', key: 'id' },
    { label: 'Nom & Prénoms', key: 'name' },
    { label: 'Email', key: 'email' },
    { label: 'Adresse', key: 'address1' },
    { label: 'Téléphone', key: 'telephone1' },
    { label: 'DATE DE CRÉATION', key: 'created_at' },
    { label: 'Actif', key: null },
    // Add more headers as needed
  ] : [
    { label: 'ID', key: 'id' },
    { label: 'Nom & Prénoms', key: 'name' },
    { label: 'Email', key: 'email' },
    { label: 'Adresse', key: 'address1' },
    { label: 'Téléphone', key: 'telephone1' },
    { label: 'Véhicule', key: null },
    { label: 'DATE DE CRÉATION', key: 'created_at' },
    { label: 'Actif', key: null },
    // Add more headers as needed
  ]


  return (
    <div className="relative overflow-x-auto">
      <div className="bg-white card-box">
        <div className="card-block">
          {/*                     <h4 className="card-title">Basic Table</h4>*/}
          <div className="table-responsive">
            <table className="table mb-0">
              <thead>
                <tr className="uppercase">
                  {HEADERS.map((h, i) => (
                    <th key={i} className="text-left cursor-pointer" onClick={() => {
                      onTableHeaderClick(h.key)
                    }}>
                      <div className="flex items-center">
                        <span className='mr-2'>
                          {h.label}
                        </span>
                        {h.key && <span>
                          <TbArrowsUpDown />
                        </span>}
                      </div>

                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {results.length > 0 &&
                  results.map((item, i) => {
                    return (
                      <tr key={i} className="py-3">
                        <td className={'cursor-pointer'} onClick={() => {
                          onSelect(item, "edit");
                        }}>{item.id}
                        </td>
                        <td className={'pointer'} onClick={() => {
                          onSelect(item, "edit");
                        }}>{item.name}
                        </td>
                        <td className={'pointer'} onClick={() => {
                          onSelect(item, "edit");
                        }}>{item.email}
                        </td>
                        <td className={'pointer'} onClick={() => {
                          onSelect(item, "edit");
                        }}>{item.address1}
                        </td>

                        <td className={'pointer'} onClick={() => {
                          onSelect(item, "edit");
                        }}>{item.telephone1}
                        </td>

                        {isDriverView &&
                          <td className={'pointer min-w-[170px] max-w-[250px]'} onClick={() => {
                            //onSelect(item, "edit");
                          }}>
                            <CarAttribution
                              items={carsData.data}
                              selectedCarId={item.vehicule_id}
                              userId={item.id}
                            /* onAttributed={onCarAttributed}
                             */
                            />
                          </td>}

                        <td className={'pointer'} onClick={() => {
                          onSelect(item, "edit");
                        }}>{formatDefaultDate(item.created_at)}
                        </td>

                        <td>
                          <SwitchComponent
                            checked={Boolean(item.is_actif)}
                            onChange={(value) => {
                              onToggleActive(item, value);
                            }}
                          />{" "}
                        </td>
                        {/*  <td>
                          <div className="flex items-center">
                            <div
                              className="text-green-600 cursor-pointer text-[14px] mr-5"
                              onClick={() => {
                                onSelect(item, "edit");
                              }}
                              title="Editer"
                            >
                              <i className="fas fa-edit"></i>
                            </div>
                          </div>
                        </td> */}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {isLoading ? (
              <TableLoader />
            ) : results.length === 0 ? (
              <h6 className="my-5 text-lg text-center">Aucune donnée</h6>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
