import { Fragment, useState, useEffect, useCallback } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  ArrowLeftIcon,
  Bars3Icon,
  XMarkIcon,
  ClockIcon,
  CalendarIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { SideBarMenu } from "./SideBarMenu";
import { clsx } from "clsx";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "../../context";
import { getAllShippings } from "../../utils";
import AuthService from "../../services/auth/AuthService";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DashLayout({
  accountNavigation,
  navigation,
  children,
  space = "admin",
}) {
  // console.log("is mobile? ", isMobile);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarTextShow, setSidebarTextShow] = useState(true);
  const { isAuth, setIsAuth } = useAppContext();
  const [searchRef, setSearchRef] = useState("");
  const [currentTime, setCurrentTime] = useState(getCurrentTime);
  const [date, setDate] = useState(getCurrentDate);
  const [city, setCity] = useState("");
  const pathname = useLocation();
  const navigate = useNavigate();
  let checkAdmin = localStorage.getItem("admincheck");

  const allShippings = getAllShippings() === true

  const handlerEnterClick = (event) => {
    // Vérifier si la touche "Enter" a été pressée (code 13)
    if (event.key === "Enter") {
      console.log("ddd");
    } else {
      console.log(event);
    }
  };

  const logOut = () => {
    localStorage.removeItem("accessToken");
    // navigate(`/`)
    window.location.href = "/";
  };

  useEffect(() => {
    console.log(navigation);
    setSidebarOpen(false);
  }, [pathname]);

  const toggleAllShippings = useCallback(() => {
    localStorage.setItem("allShippings", allShippings ? false : true)
    //reload page
    window.location.reload()
  }, [allShippings])

  const goToAdmin = () => {
    localStorage.setItem("client", localStorage.getItem("oldToken"));
    AuthService.setHeader(localStorage.getItem("oldToken"));
    localStorage.setItem("persist:root", localStorage.getItem("persist:rootOld"));

    localStorage.removeItem("oldToken");

    localStorage.removeItem("persist:rootOld");
    localStorage.removeItem("admincheck");

    // navigate("/admin/dash/utilisateurs");
    window.location.href = "/admin/dash/utilisateurs"
  };

  const renderAllShippingBtn = (<button
    type="button"
    className={`h-auto w-auto mx-1 rounded-md p-2 text-sm font-medium ${allShippings ? "bg-red-500 " : "bg-gray-500 text-white"}`}
    onClick={toggleAllShippings}
  >
    <span className={"interface-icon"}>
      <i className={"fa fa-sitemap"}></i>
    </span>
    <span className={"interface-text"}>
      Toutes les expéditions
    </span>
  </button>)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(getCurrentTime);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <section className={"bg-gray-200 min-h-screen"}>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex flex-1 w-full max-w-xs mr-16">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 flex justify-center w-16 pt-5 left-full">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="w-6 h-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <SideBarMenu
                    menuData={navigation}
                    accountNavigation={accountNavigation}
                    sidebarTextShow={sidebarTextShow}
                    space={space}
                    setSidebarTextShow={setSidebarTextShow}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div
          className={clsx(
            { "lg:w-68": sidebarTextShow, "lg:w-20": !sidebarTextShow },
            "hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex  lg:flex-col"
          )}
        >
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <SideBarMenu
            menuData={navigation}
            accountNavigation={accountNavigation}
            sidebarTextShow={sidebarTextShow}
            space={space}
            setSidebarTextShow={setSidebarTextShow}
          />
        </div>

        <div
          className={clsx({
            "lg:pl-72": sidebarTextShow,
            "lg:pl-20": !sidebarTextShow,
          })}
        >
          {/* {clsx({"lg:p-72":sidebarTextShow,"lg:p-26":!sidebarTextShow,})} */}
          {/*  "lg:pl-8 lg:pr-4": sidebarTextShow */}
          <div
            className={clsx(
              { "pl-2": !sidebarTextShow },
              "sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b sm:gap-x-6 "
            )}
          >
            <div
              className={
                "border-gray-200 bg-white flex  flex-1 h-full py-3 rounded-lg"
              }
            >
              <button
                type="button"
                className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon
                  className={clsx(
                    {
                      "text-paris": space === "paris",
                      "text-medical": space === "medical",
                      "text-service": space === "service",
                      "text-admin": space === "admin",
                    },
                    "w-10 h-10"
                  )}
                  aria-hidden="true"
                />
              </button>

              {/* Separator */}
              <div
                className="w-px h-6 bg-gray-900/10 lg:hidden"
                aria-hidden="true"
              />

              <div className="flex items-center self-stretch justify-between flex-1 gap-x-4 lg:gap-x-6">
                <div className={"flex items-center gap-4 pl-4"}>
                  <div>
                    <span className={"hidden md:block cursor-pointer"}>
                      <ArrowLeftIcon
                        className={clsx(
                          {
                            "text-paris": space === "paris",
                            "text-medical": space === "medical",
                            "text-service": space === "service",
                            "text-admin": space === "admin",
                          },
                          "w-6 h-6"
                        )}
                        aria-hidden="true"
                        onClick={() => navigate(-1)}
                      />
                    </span>
                  </div>

                  <div className="relative text-sm">
                    <div className="items-center flex-1 hidden gap-2 md:flex ">
                      <div className={"flex items-center gap-1"}>
                        <CalendarIcon
                          className={clsx(
                            {
                              "text-paris": space === "paris",
                              "text-medical": space === "medical",
                              "text-service": space === "service",
                              "text-admin": space === "admin",
                            },
                            "w-6 h-6"
                          )}
                        />
                        <span className={""}>{date}</span>
                      </div>
                      <div className={"flex items-center gap-1"}>
                        <ClockIcon
                          className={clsx(
                            {
                              "text-paris": space === "paris",
                              "text-medical": space === "medical",
                              "text-service": space === "service",
                              "text-admin": space === "admin",
                            },
                            "w-6 h-6"
                          )}
                        />
                        <span className={""}>{currentTime}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex items-center gap-x-4 lg:gap-x-6">
                  {/* debut des buttons pour les changements de menus */}
                  <div>
                    <ul className={"flex gap-3"}>
                      {isAuth?.userRole === "admin" ||
                        isAuth?.userRole === "ops" ? (
                        <li className="nav-item">
                          {(isAuth?.roles?.includes("ROLE_OPS_PARIS") ||
                            isAuth?.roles?.includes("ROLE_ADMIN")) && (
                              <button
                                type="button"
                                className={clsx({
                                  active: isAuth?.spaceName?.includes === "paris",
                                }, "bg-paris text-sm font-medium rounded-lg px-4 py-2 text-white mx-2")}
                                onClick={() => {
                                  window.location.href = "/ops/paris/dash";
                                  // navigate("/ops/paris/dash")
                                }}
                              >
                                <span className={"interface-icon"}>
                                  <i className={"fa fa-university"}></i>
                                </span>
                                <span className={"interface-text"}>Paris</span>
                              </button>
                            )}

                          {(isAuth?.roles?.includes("ROLE_OPS_MEDICAL") ||
                            isAuth?.roles?.includes("ROLE_ADMIN")) && (
                              <button
                                type="button"
                                className={clsx({
                                  active:
                                    isAuth?.spaceName?.includes === "medical",
                                }, "bg-medical text-sm font-medium rounded-lg px-4 py-2 text-white mx-2")}
                                onClick={() => {
                                  window.location.href = "/ops/medical/dash";
                                  // navigate("/ops/medical/dash");
                                }}
                              >
                                <span className={"interface-icon"}>
                                  <i className={"fa fa-stethoscope"}></i>
                                </span>
                                <span className={"interface-text"}>Medicals</span>
                              </button>
                            )}

                          {(isAuth?.roles?.includes("ROLE_OPS_SERVICE") ||
                            isAuth?.roles?.includes("ROLE_ADMIN")) && (
                              <button
                                type="button"
                                className={clsx({
                                  active:
                                    isAuth?.spaceName?.includes === "service",
                                }, "bg-service text-sm font-medium rounded-lg px-4 py-2 text-white mx-2")}
                                onClick={() => {
                                  window.location.href = "/ops/service/dash";
                                  // navigate("/ops/service/dash");
                                }}
                              >
                                <span className={"interface-icon"}>
                                  <i className={"fa fa-fire"}></i>
                                </span>
                                <span className={"interface-text"}>Services</span>
                              </button>
                            )}
                          {isAuth?.roles?.includes("ROLE_ADMIN") && (
                            <button
                              type="button"
                              className={clsx({
                                active: isAuth?.spaceName?.includes === "admin",
                              }, "bg-admin text-sm font-medium rounded-lg px-4 py-2 text-white mx-2")}
                              onClick={() => {
                                window.location.href = "/admin/dash";
                                // navigate("/admin/dash");
                              }}
                            >
                              <span className={"interface-icon"}>
                                <i className={"fa fa-cog"}></i>
                              </span>
                              <span className={"interface-text"}>Admin</span>
                            </button>
                          )}

                          {/* All render button */}
                          {isAuth?.userRole !== "admin" && <>
                            {renderAllShippingBtn}
                          </>}

                        </li>
                      ) : isAuth?.userRole !== "exploitation" ? (
                        <>{/* {renderAllShippingBtn} */}</>
                      ) : // <li className="nav-item">
                        //     <button
                        //         style={{
                        //             height: "auto",
                        //             margin: "auto",
                        //             marginLeft: "5px",
                        //             marginRight: "5px",
                        //         }}
                        //         type="button"
                        //         className="btn btn-outline-all"
                        //     >
                        //         <span className={"interface-icon"}>
                        //             <i className={"fa fa-sitemap"}></i>
                        //         </span>
                        //         <span className={"interface-text"}>
                        //             Toutes les expéditions
                        //         </span>
                        //     </button>
                        // </li>
                        null}
                      {checkAdmin &&
                        (checkAdmin === "true" || checkAdmin === true) && (
                          <li className="nav-item">
                            <button
                              style={{
                                height: "auto",
                                margin: "auto",
                                marginLeft: "5px",
                                marginRight: "5px",
                              }}
                              type="button"
                              className="btn btn-danger"
                              onClick={() => {
                                goToAdmin();
                              }}
                            >
                              <span className={"interface-icon"}>
                                <i className={"fa fa-sitemap"}></i>
                              </span>
                              <span className={"interface-text"}>
                                Retour à l'admin
                              </span>
                            </button>
                          </li>
                        )}
                    </ul>
                  </div>
                  {/* fin */}

                  {/* Separator */}
                  <div
                    className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
                    aria-hidden="true"
                  />

                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ">
                    <Menu.Button className="-m-1.5 flex items-center p-1.5 xs:mr-3 md:mr-0">
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="w-8 h-8 rounded-full bg-gray-50 "
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      />
                      <span className="items-center hidden md:flex">
                        <span
                          className="ml-4 text-sm font-normal leading-6 text-gray-900"
                          aria-hidden="true"
                        >
                          {isAuth?.name && <>{isAuth?.name}.</>}

                          <small className={"block text-xs "}>
                            {" "}
                            {isAuth?.userRole?.toUpperCase()}{" "}
                          </small>
                        </span>
                        <ChevronDownIcon
                          className={clsx(
                            {
                              "text-paris": space === "paris",
                              "text-medical": space === "medical",
                              "text-service": space === "service",
                              "text-admin": space === "admin",
                            },
                            "w-8 h-8 mx-2"
                          )}
                          aria-hidden="true"
                        />
                      </span>
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                        <Menu.Item key={"item.name"}>
                          <Link
                            to={`/${isAuth?.role?.toLowerCase()}/account`}
                            className={
                              "block px-3 py-1 text-sm leading-6 text-gray-900"
                            }
                          >
                            Votre profil
                          </Link>
                        </Menu.Item>

                        <Menu.Item key={"item.name"}>
                          <span
                            onClick={logOut}
                            className={
                              "block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer "
                            }
                          >
                            Déconnexion
                          </span>
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>

          <main className="py-5 m">
            <div className="">
              <section className={clsx({ "px-2": !sidebarTextShow })}>
                {children}
              </section>
            </div>
          </main>
        </div>
      </div>
    </section>
  );
}

const getCurrentTime = () => {
  const date = new Date();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  let seconds = date.getSeconds();
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes
    }:${seconds < 10 ? seconds : seconds}`;
};

const getCurrentDate = () => {
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day < 10 ? "0" + day : day}/${month < 10 ? "0" + month : month
    }/${year}`;
};
