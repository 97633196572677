import React, { useEffect, useState, useCallback, Fragment } from "react";
import Restaure from "../Tableaux/Expeditions/Restaure";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  exnetSpaceColor,
  expeditionTypeByCountries,
  refPrefixer,
  returnExpeditionTypeLogo,
  splitText,
  urlSplit,
} from "../../utils/Functions/otherFunctions";
import {
  TbArrowsDiff,
  TbEdit,
  TbFileDownload,
  TbMessageCircle2,
  TbMotorbike,
  TbPackageExport,
  TbPackageImport,
  TbTruckDelivery,
} from "react-icons/tb";
import moment from "moment";
import { getCountryNameOrCode } from "../../utils/const/countriesConstant";
import ExpeditionService from "../../services/expedition/ExpeditionService";
import { ToastContainer, toast } from "react-toastify";
import useDisclosure from "../../hooks/useDisclosure";
import InformationModal from "../Tableaux/Expeditions/DispatchFormModal";
import { useBasicFormValues } from "../../hooks/useFormValues";
import JSZip from "jszip";
import { getTableBGColor } from "../../utils";
import { useAppContext } from "../../context";
import { EllipsisVerticalIcon, PencilIcon } from "@heroicons/react/24/outline";
import { Menu, Transition } from "@headlessui/react";



const TableRowComponent = ({
  item: value,
  onAttributionSelect,
  handleCheckboxChange,
  sortedArray,
  checkboxVisible,
  onSelect,
  onDispatchModalSelect,
  onAddTrackingClick,
  isChecked,
  allowCheckBoxToggler = false,
  shippingLink,
  isHighlighted,
  linkTarget = "_self",
  children
}) => {
  const navigate = useNavigate();
  const { isAuth, setIsAuth } = useAppContext();
  const { pathname } = useLocation();
  const { exnetSpace, role: userRole } = urlSplit();
  //const [item, setItem] = useState({ ...value });
  const { data: item, setData: setItem, setFormValue } = useBasicFormValues({ ...value })
  const [redirectionUrl, setRedirectionUrl] = useState("");
  const [messageUrl, setmessageUrl] = useState("");
  const { isOpen, onClose, onOpen } = useDisclosure()

  const resendEmail = () => {
    ExpeditionService.resendMail({ shipping_id: item.id, type: exnetSpace }).then(
      (res) => {
        let { data: { message } } = res
        toast.success('Email renvoyé avec succès', {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

      },
      (error) => {
        toast.error('Email non renvoyé', {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    )
  }

  const handleDownloadClick = useCallback(async () => {
    let fileLinks = [
      `${process.env.REACT_APP_BACKEND_HOST}/${item.doc_borderaux}`
    ];

    if (value?.doc_accompagnement) {
      // value?.documents.forEach(element => {
      //   fileLinks.push( `${process.env.REACT_APP_BACKEND_HOST}/${element.document}`)
      // });
    }



    const zip = new JSZip();

    await Promise.all(
      fileLinks.map(async (fileLink) => {
        try {
          const response = await fetch(fileLink);
          if (!response.ok) {
            throw new Error(`Failed to fetch: ${fileLink}`);
          }

          const fileBlob = await response.blob();
          console.log(`File blob for ${fileLink}:`, fileBlob); // Ajoutez cette ligne pour déboguer

          const fileName = fileLink.split('/').pop();
          if (fileName.endsWith('.pdf')) {
            // Traitement spécifique pour les fichiers PDF
            const pdfBuffer = await fileBlob.arrayBuffer();
            const pdfBlob = new Blob([pdfBuffer], { type: 'application/pdf' });
            zip.file(fileName, pdfBlob);
          } else if (fileName.match(/\.(jpg|jpeg|png|gif)$/i)) {
            // Traitement spécifique pour les images (ajoutez d'autres extensions si nécessaire)
            const imageBlob = new Blob([fileBlob], { type: 'image/*' });
            zip.file(fileName, imageBlob);
          } else {
            // Traitement générique pour les autres types de fichiers
            zip.file(fileName, fileBlob);
          }
        } catch (error) {
          console.error(`Error fetching ${fileLink}:`, error);
        }
      })
    );

    zip.generateAsync({ type: 'blob' }).then((blob) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'files.zip';
      link.click();
    });
  }, []);

  const ZipGenerator = () => {
    handleDownloadClick()
  }

  useEffect(() => {
    if (exnetSpace) {
      let pathnameTable = pathname.split("/");
      let path =
        pathnameTable.length > 5
          ? `/${pathnameTable[1]}/${exnetSpace}/dash/${pathnameTable[4]}/${pathnameTable[5]}/${item.ref}`
          : pathnameTable.length > 4
            ? `/${pathnameTable[1]}/${exnetSpace}/dash/${pathnameTable[4]}/${item.ref}`
            : `/${pathnameTable[1]}/${exnetSpace}/dash/detail/${item.ref}`;

      let chat = `/${pathnameTable[1]}/${exnetSpace}/dash/message/${item.id}`;
      setRedirectionUrl(shippingLink || path);
      setmessageUrl(chat)
    }
  }, [item, exnetSpace, pathname, shippingLink]);


  return (
    <>
      <InformationModal
        isOpen={isOpen}
        onClose={onClose}
        selectedData={item}
        type={item.type}
        onSubmit={note => {
          const infos = item?.information_importante || []
          setFormValue("information_importante", [note, ...infos])
        }}
      />

      <tr key={item?.ref} className={`cursor-pointer text-uppercase tableRow ${isHighlighted ? "bg-gray-200" : "bg-inherit"}`}
      //style={{ backgroundColor: isHighlighted ? getTableBGColor(value.type) : "inherit" }}
      >
        {checkboxVisible && (
          <td className="mr-2">
            <input
              key={"checkbox_" + item?.ref}
              type="checkbox"
              checked={isChecked}
              onChange={() => handleCheckboxChange(item)}
            />
          </td>
        )}
        {(checkboxVisible && allowCheckBoxToggler) && (
          <td></td>
        )}
        <td
        // onClick={() => {
        //   localStorage.setItem("expeditions", JSON.stringify(sortedArray));
        //   navigate(redirectionUrl);
        //   window.open(redirectionUrl, '_blank');

        // }}


        >
          <Link to={shippingLink || redirectionUrl} target={linkTarget} className={"text-sm font-normal"} style={{ color: exnetSpaceColor(item.type) }}>
            {refPrefixer(item.type)}
            {item.ref} <br />
            <div
              style={{
                display: "flex",
                gap: 1,
                alignItems: "center",
                justifyContent: "center",

              }}

            >
              {((item.type_expedition === "course" || item.type_expedition === "courses")) ?
                <>
                  {(item.type_de_roue === "quatre_roues" || item.type_de_roue === "4") ?
                    <>
                      <TbTruckDelivery color={exnetSpaceColor(item.type)} size={25} />
                    </>
                    :
                    <>
                      <TbMotorbike color={exnetSpaceColor(item.type)} size={25} />
                    </>
                  }

                </>
                :
                <>
                  {expeditionTypeByCountries(
                    item?.expeditor?.country,
                    item?.receiver?.country
                  ) === "export" ? (
                    <img src={returnExpeditionTypeLogo('export', item.type)} className={'mt-2 w-10'} alt="" />
                  ) : expeditionTypeByCountries(
                    item?.expeditor?.country,
                    item?.receiver?.country
                  ) === "import" ? (
                    <img src={returnExpeditionTypeLogo('import', item.type)} className={'mt-2 w-10'} alt="" />
                  ) : (
                    <img src={returnExpeditionTypeLogo('export', item.type)} className={'mt-2 w-10'} alt="" />
                  )}

                </>
              }



            </div>
          </Link>

        </td>

        <td
          className={"text-center"}
          style={{
            maxWidth: "50px",
          }}
          onClick={() => {
            localStorage.setItem("expeditions", JSON.stringify(sortedArray));
          }}
        >
          <Link to={redirectionUrl} target={linkTarget} className={"text-sm font-normal"} style={{ color: exnetSpaceColor(item.type) }} >{item.customer_ref ? item.customer_ref : "-"}</Link>

        </td>

        <td
          style={{
            maxWidth: "200px",
          }}
          onClick={() => {
            localStorage.setItem("expeditions", JSON.stringify(sortedArray));
          }}
        >
          <Link to={redirectionUrl} target={linkTarget} className={'text-black'} >

            {" "}
            <span className={" font-bold"}>
              {splitText(item.expeditor.fullname)}
            </span>
            <br />
            <span>{splitText(item.expeditor.company)}</span>
            <br />
            <i className="font-bold ">
              {" "}
              {moment(item.pickup_date).format("DD-MM-YYYY")}
            </i>
            <br />
            <i className="font-bold "> {item.pickup_time}</i>
            <br />
          </Link>

        </td>

        {exnetSpace === "medical" && (
          <td
            style={{
              maxWidth: "100px",
            }}
            className={"text-center"}
            onClick={() => {
              localStorage.setItem("expeditions", JSON.stringify(sortedArray));
              // navigate(redirectionUrl);
            }}
          >
            <Link to={redirectionUrl} target={linkTarget} className={'text-black text-sm font-normal'} > {item.code_donneur ? item.code_donneur : "-"}</Link>

          </td>
        )}

        <td
          onClick={() => {
            localStorage.setItem("expeditions", JSON.stringify(sortedArray));
            // navigate(redirectionUrl);
          }}
        >
          <Link to={redirectionUrl} target={linkTarget} className={'text-black text-sm font-normal'} >
            <span> {splitText(item.expeditor.address1)}</span> <br />
            <span>{item.expeditor.city}</span>
            <br />
            <span>{item.expeditor.postalCode}</span>
            <div className={'flex items-center gap-3 flex-wrap'}>
              <span>
                <img
                  src={`https://flagcdn.com/16x12/${getCountryNameOrCode(
                    getCountryNameOrCode(item?.expeditor?.country),
                    true
                  )}.png`}
                  srcSet={`https://flagcdn.com/32x24/${getCountryNameOrCode(
                    getCountryNameOrCode(item?.expeditor?.country),
                    true
                  )}.png 2x,
              https://flagcdn.com/48x36/${getCountryNameOrCode(
                    getCountryNameOrCode(item?.expeditor?.country),
                    true
                  )}.png 3x`}
                  width="16"
                  height="12"
                  alt={getCountryNameOrCode(item?.expeditor?.country)}
                />
              </span>
              <span> {getCountryNameOrCode(item?.expeditor?.country)}</span>
            </div>
          </Link>
        </td>

        <td
          style={{
            backgroundColor: item.color ? item.color : "",
            maxWidth: "200px",
          }}
          onClick={() => {
            localStorage.setItem("expeditions", JSON.stringify(sortedArray));
            // navigate(redirectionUrl);
          }}
        >
          <Link to={redirectionUrl} target={linkTarget} className={'text-black'} >
            {" "}
            <span className={"font-bold"}>
              {splitText(item.receiver.fullname)}
            </span>
            <br />
            <span>{item.receiver.company}</span> <br />
            <i className="font-bold ">
              {moment(item.delivery_date).format("DD-MM-YYYY")}{" "}
            </i>
            <br />
            <i className="font-bold "> {item.delivery_time}</i>
          </Link>
        </td>
        {exnetSpace === "medical" && (
          <td
            style={{
              maxWidth: "100px",
            }}
            className={"text-center"}
            onClick={() => {
              localStorage.setItem("expeditions", JSON.stringify(sortedArray));
              // navigate(redirectionUrl);
            }}
          >
            <Link to={redirectionUrl} target={linkTarget} className={'text-black text-sm font-normal'} >{item.code_receveur ? item.code_receveur : "-"}</Link>

          </td>
        )}


        <td onClick={() => {
          localStorage.setItem("expeditions", JSON.stringify(sortedArray));
          // navigate(redirectionUrl);
        }}>
          <Link to={redirectionUrl} target={linkTarget} className={'text-black text-sm font-normal'} >
            <span> {splitText(item.receiver.address1)}</span> <br />
            <span>{item.receiver.city}</span>
            <br />
            <span>{item.receiver.postalCode}</span>
            <div className={'flex items-center gap-3 flex-wrap'}>
              <span>
                <img
                  src={`https://flagcdn.com/16x12/${getCountryNameOrCode(
                    getCountryNameOrCode(item?.receiver?.country),
                    true
                  )}.png`}
                  srcSet={`https://flagcdn.com/32x24/${getCountryNameOrCode(
                    getCountryNameOrCode(item?.receiver?.country),
                    true
                  )}.png 2x,
              https://flagcdn.com/48x36/${getCountryNameOrCode(
                    getCountryNameOrCode(item?.receiver?.country),
                    true
                  )}.png 3x`}
                  width="16"
                  height="12"
                  alt={getCountryNameOrCode(item?.receiver?.country)}
                />
              </span>
              <span> {getCountryNameOrCode(item?.receiver?.country)}</span>
            </div>
          </Link>
        </td>
        <td className="max-w-[100px]">
          <div>
            {item.billing_center?.billing_center_name}
          </div>
        </td>
        <td
          style={{
            maxWidth: "70px",
          }}>
          {" "}
          <div className="flex flex-col text-sm font-normal item-center">
            <span className="mr-2">
              <TbEdit
                onClick={() => onAddTrackingClick(item, setItem)}
                color={exnetSpaceColor(item.type)}
              />{" "}
            </span>
            <span>{item?.suivis?.length > 0 ? item.suivis[0]['details'].slice(0, 50) + "..." : ''}</span>
          </div>
        </td>

        <td style={{
          maxWidth: "50px",
        }}>
          <div className="flex flex-col items-center text-sm font-normal">
            <TbEdit
              onClick={onOpen}
              color={exnetSpaceColor(item.type)}
              className="mr-1 text-lg"
            />
            <span>{item.information_importante?.[0]?.information}</span>
          </div>
        </td>

        {!checkboxVisible && <>
          <td className="text-center">
            {children}
          </td>
          {pathname.split('/')[pathname.split('/').length - 1] !== "archivee" ?
            <td>
              <div>
                <Menu as="div" className="relative flex items-start justify-center w-auto text-center ">
                  <Menu.Button className="-m-1.5 flex items-start p-1.5 md:mr-0 justify-center">
                    <span className="sr-only">Open user menu</span>
                    <EllipsisVerticalIcon className="w-8 h-8 text-6xl font-bold text-right " />

                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                    className={"divide-y-2 space-y-1"}
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-44 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">

                      <Menu.Item key={"item.name"}>
                        <span
                          onClick={() => onSelect(item, true)}
                          className={
                            "block px-3 py-1 text-left text-xs leading-0 text-gray-900"
                          }
                        >
                          <i className={`fa fa-edit mr-1`}></i>
                          Modifier
                        </span>
                      </Menu.Item>

                      <Menu.Item key={"item.name"}>
                        <span
                          onClick={() => onDispatchModalSelect(item)}
                          className={
                            "block px-3 py-1 text-left text-xs leading-0 text-gray-900"
                          }
                        >
                          <i className={`fa fa-plus mr-1`}></i>
                          Informations importantes
                        </span>
                      </Menu.Item>

                      <Menu.Item key={"item.name"}>
                        <span
                          onClick={() => onAddTrackingClick(item)}
                          className={
                            "block px-3 py-1 text-left text-xs leading-0 text-gray-900"
                          }
                        >
                          <i className={`fa fa-plus mr-1`}></i>
                          Ajouter tracking
                        </span>
                      </Menu.Item>

                      <Menu.Item>
                        <Link
                          to={
                            item.doc_borderaux
                              ? `${process.env.REACT_APP_BACKEND_HOST}/${item.doc_borderaux}`
                              : "#"
                          }
                          download
                          target={"_blank"}
                          className={"block px-3 py-1 text-left text-xs leading-0 text-gray-900 hover:text-gray-900"}
                          style={{fontSize:'12px !important',fontWeight:'lighter'}}
                        >
                          <i className={`fa fa-download mr-1`}></i>
                          <span className={"text-xs leading-0 text-gray-900 hover:text-gray-900"}>
                            Télécharger borderau
                          </span>

                        </Link>
                      </Menu.Item>


                      <Menu.Item key={"item.name"}>
                        <span
                          onClick={() => {
                            onAttributionSelect(item, "chauffeur");
                          }}
                          className={
                            "block px-3 py-1 text-left text-xs leading-0 text-gray-900"
                          }
                        >
                          <i className={`fa fa-user mr-1`}></i>
                          Attribuer à chauffeur

                        </span>
                      </Menu.Item>

                      <Menu.Item key={"item.name"}>
                        <span
                          onClick={() => {
                            onAttributionSelect(item, "sedentaire");
                          }}
                          className={
                            "block px-3 py-1 text-left text-xs leading-0 text-gray-900"
                          }
                        >
                          <i className={`fa fa-user mr-1`}></i>
                          Attribuer à sédentaire
                        </span>
                      </Menu.Item>

                      <Menu.Item key={"item.name"}>
                        <span
                          onClick={() => {
                            onAttributionSelect(item, "voyageur");
                          }}
                          className={
                            "block px-3 py-1 text-left text-xs leading-0 text-gray-900"
                          }
                        >
                          <i className={`fa fa-user mr-1`}></i>
                          Attribuer à voyageur
                        </span>
                      </Menu.Item>

                      <Menu.Item key={"item.name"}>
                        <span
                          onClick={resendEmail}
                          className={
                            "block px-3 py-1 text-left text-xs leading-0 text-gray-900"
                          }
                        >
                          <i className={`fa fa-envelope mr-1`}></i>
                          Renvoyer Mail
                        </span>
                      </Menu.Item>

                      {/* <Menu.Item key={"item.name"}>
                        <Link
                          className="dropdown-item "
                          to={messageUrl}
                          target={linkTarget}

                        >
                          <i className={`fa fa-comment mr-1`}></i>
                          Messagerie
                        </Link>
                      </Menu.Item> */}

                      <Menu.Item key={"item.name"}>
                        <Restaure shipping_id={item.id} />
                      </Menu.Item>


                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              {/* <div className="dropdown dropdown-action">
                <a
                  href="#"
                  className="action-icon dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fas fa-ellipsis-v"></i>
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                  <a
                    className="dropdown-item "
                    href="#"
                    onClick={() => onSelect(item, true)}
                  >
                    <i className={`fa fa-edit mr-1`}></i>
                    Modifier
                  </a>

                  <a
                    className="dropdown-item "
                    href="#"
                    onClick={() => onDispatchModalSelect(item)}
                  >
                    <i className={`fa fa-plus mr-1`}></i>
                    Informations importantes
                  </a>

                  <a
                    className="dropdown-item "
                    href="#"
                    onClick={() => onAddTrackingClick(item)}
                  >
                    <i className={`fa fa-plus mr-1`}></i>
                    Ajouter tracking
                  </a>

                  <a
                    href={
                      item.doc_borderaux
                        ? `${process.env.REACT_APP_BACKEND_HOST}/${item.doc_borderaux}`
                        : "#"
                    }
                    download
                    className="dropdown-item"
                    target={"_blank"}
                  >
                    <i className={`fa fa-download mr-1`}></i>
                    Télécharger borderau
                  </a>

                  <a
                    className="dropdown-item "
                    href="#"
                    onClick={() => {
                      onAttributionSelect(item, "chauffeur");
                    }}
                  >
                    <i className={`fa fa-user mr-1`}></i>
                    Attribuer à chauffeur
                  </a>

                  <a
                    className="dropdown-item "
                    href="#"
                    onClick={() => {
                      onAttributionSelect(item, "sedentaire");
                    }}
                  >
                    <i className={`fa fa-user mr-1`}></i>
                    Attribuer à sédentaire
                  </a>

                  <a
                    className="dropdown-item "
                    href="#"
                    onClick={() => {
                      onAttributionSelect(item, "voyageur");
                    }}
                  >
                    <i className={`fa fa-user mr-1`}></i>
                    Attribuer à voyageur
                  </a>
                  <a
                    className="dropdown-item "
                    href="#"
                    onClick={resendEmail}
                  >
                    <i className={`fa fa-envelope mr-1`}></i>
                    Renvoyer Mail
                  </a>
                  <Link
                    className="dropdown-item "
                    to={messageUrl}
                    target={linkTarget}

                  >
                    <i className={`fa fa-comment mr-1`}></i>
                    Messagerie
                  </Link>
                  <Restaure shipping_id={item.id} />
                </div>
              </div> */}
            </td>
            :
            <td>
              <button
                type="button"
                className="flex p-2 rounded-lg"
                onClick={ZipGenerator}
                style={{ color: '#fff', backgroundColor: `${exnetSpaceColor(item.type)}` }}
              ><i className="fa fa-download ms-1"></i>
                Télécharger
              </button>
            </td>
          }
        </>}
      </tr>

    </>
  );
}

export default TableRowComponent;



export const ClientTableRow = ({ item }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { exnetSpace, role: userRole } = urlSplit();

  const [redirectionUrl, setRedirectionUrl] = useState("");
  const [messageUrl, setmessageUrl] = useState("");
  const { isAuth, setIsAuth } = useAppContext();


  useEffect(() => {
    if (exnetSpace) {
      let pathnameTable = pathname.split("/");
      let path =
        pathnameTable.length > 5
          ? `/${isAuth?.userRole}/${isAuth?.spaceName}/dash/${pathnameTable[4]}/${pathnameTable[5]}/${item.ref}`
          : pathnameTable.length > 4
            ? `/${isAuth?.userRole}/${isAuth?.spaceName}/dash/${pathnameTable[4]}/${item.ref}`
            : `/${isAuth?.userRole}/${isAuth?.spaceName}/dash/detail/${item.ref}`;

      let chat = `/${isAuth?.userRole}/${isAuth?.spaceName}/dash/message/${item.id}`;
      setRedirectionUrl(path);
      setmessageUrl(chat)
    }
  }, [item, exnetSpace, pathname]);

  return (
    <tr key={item?.ref} className="cursor-pointer text-uppercase tableRow">
      <td

      >
        <Link to={redirectionUrl} className={"text-sm font-normal "} style={{ color: exnetSpaceColor(item.type) }}  >

          {refPrefixer(item.type)}
          {item.ref}{" "}
          <div
            style={{
              display: "flex",
              gap: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <br />
            {((item.type_expedition === "course" || item.type_expedition === "courses")) ?
              <>
                {(item.type_de_roue === "quatre_roues" || item.type_de_roue === "4") ?
                  <>
                    <TbTruckDelivery color={exnetSpaceColor(item.type)} size={25} />
                  </>
                  :
                  <>
                    <TbMotorbike color={exnetSpaceColor(item.type)} size={25} />
                  </>
                }

              </>
              :
              <>
                {expeditionTypeByCountries(
                  item?.expeditor?.country,
                  item?.receiver?.country
                ) === "export" ? (
                  <img src={returnExpeditionTypeLogo('export', item.type)} className={'mt-2 w-10'} alt="" />
                ) : expeditionTypeByCountries(
                  item?.expeditor?.country,
                  item?.receiver?.country
                ) === "import" ? (
                  <img src={returnExpeditionTypeLogo('import', item.type)} className={'mt-2 w-10'} alt="" />
                ) : (
                  <img src={returnExpeditionTypeLogo('export', item.type)} className={'mt-2 w-10'} alt="" />
                )}

              </>
            }
          </div>
        </Link>

      </td>

      <td style={{ maxWidth: "250px" }} >
        {" "}
        <Link to={redirectionUrl} className={'text-black min-h-fit overflow-y-scroll max-h-60 '} >
          <span className="font-bold">
            {splitText(item.expeditor.fullname)}
          </span>
          <br />
          <span>{splitText(item.expeditor.company)}</span>
          <br />
          <i className={" font-bold "}>
            {" "}
            {moment(item.pickup_date).format("DD-MM-YYYY")}
          </i>
          <br />
          <i className={"font-bold "}>{item.pickup_time}</i>
        </Link>

      </td>
      {isAuth?.spaceName === "medical" && (
        <td className={"text-center"} style={{ maxWidth: "50px" }}>
          <Link to={redirectionUrl} className={'text-black text-sm font-normal'} >{item.customer_ref ? item.customer_ref : "-"}</Link>

        </td>
      )}
      {isAuth?.spaceName === "medical" && (
        <td style={{ maxWidth: "100px" }} className={"text-center text-sm font-normal"} >
          <Link to={redirectionUrl} className={'text-black'} >  {item.code_donneur ? item.code_donneur : "-"}</Link>
        </td>
      )}

      <td >
        <Link to={redirectionUrl} className={'text-black text-sm font-normal'} >
          <span> {splitText(item.expeditor.address1)}</span> <br />
          <span>{item.expeditor.city}</span>
          <br />
          <span>{item.expeditor.postalCode}</span>
          <div
            className={'flex items-center gap-3 flex-wrap'}
          >
            <span>
              <img
                src={`https://flagcdn.com/16x12/${getCountryNameOrCode(
                  getCountryNameOrCode(item?.expeditor?.country),
                  true
                )}.png`}
                srcSet={`https://flagcdn.com/32x24/${getCountryNameOrCode(
                  getCountryNameOrCode(item?.expeditor?.country),
                  true
                )}.png 2x,
                  https://flagcdn.com/48x36/${getCountryNameOrCode(
                  getCountryNameOrCode(item?.expeditor?.country),
                  true
                )}.png 3x`}
                width="16"
                height="12"
                alt={getCountryNameOrCode(item?.expeditor?.country)}
              />
            </span>{" "}
            <span> {getCountryNameOrCode(item?.expeditor?.country)}</span>
          </div>
        </Link>

      </td>

      <td>
        <Link to={redirectionUrl} className={'text-black'} >
          {" "}
          <span className="font-bold">
            {splitText(item.receiver.fullname)}
          </span>
          <br />
          <span>{item.receiver.company}</span>
          <br />
          <i className={"font-bold "}>
            {moment(item.delivery_date).format("DD-MM-YYYY")}{" "}
          </i>
          <br />
          <i className={"font-bold "}>{item.delivery_time} </i>
        </Link>
      </td>
      {isAuth?.spaceName === "medical" && (
        <td style={{ maxWidth: "100px" }} className={"text-center text-sm font-normal"} >
          <Link to={redirectionUrl} className={'text-black'} >{item.code_receveur ? item.code_receveur : "-"}</Link>
        </td>
      )}

      <td>
        <Link to={redirectionUrl} className={'text-black text-sm font-normal '} >
          <span> {splitText(item.receiver.address1)}</span> <br />
          <span>{item.receiver.city}</span>
          <br />
          <span>{item.receiver.postalCode}</span>
          <div
            className={'flex items-center gap-3 flex-wrap'}
          >
            <span>
              <img
                src={`https://flagcdn.com/16x12/${getCountryNameOrCode(
                  getCountryNameOrCode(item?.receiver?.country),
                  true
                )}.png`}
                srcSet={`https://flagcdn.com/32x24/${getCountryNameOrCode(
                  getCountryNameOrCode(item?.receiver?.country),
                  true
                )}.png 2x,
                  https://flagcdn.com/48x36/${getCountryNameOrCode(
                  getCountryNameOrCode(item?.receiver?.country),
                  true
                )}.png 3x`}
                width="16"
                height="12"
                alt={getCountryNameOrCode(item?.receiver?.country)}
              />
            </span>
            <span> {getCountryNameOrCode(item?.receiver?.country)}</span>
          </div>
        </Link>
      </td>

      <td >
        <Link to={redirectionUrl} className={'text-black text-sm font-normal'} >
          <div className="flex overflow-y-scroll item-center max-h-52 ">
            <span>{item?.suivis?.length > 0 ? item.suivis[0]['details'] : ''}</span>
          </div>
        </Link>
      </td>
      <td
        style={{
          maxWidth: "50px !important",
          alignItems: "center",
        }}

      >
        <div className={"my-4 flex items-center gap-1"}>
          {" "}
          <Link
            target={'_blank'}
            to={
              item.doc_borderaux
                ? `${process.env.REACT_APP_BACKEND_HOST}/${item.doc_borderaux}`
                : "#"
            }
            download={true}
            className="p-2 mx-4 my-auto text-xl rounded-lg"
            style={{
              color: "#fff",
              backgroundColor: `${exnetSpaceColor(isAuth?.spaceName)}`,
            }}
          >
            <TbFileDownload size={25} />
          </Link>
          {/* <Link
            to={messageUrl}
            className="mx-1 my-auto btn"
            style={{
              color: "#fff",
              backgroundColor: `${exnetSpaceColor(exnetSpace)}`,
            }}
          >
            <TbMessageCircle2 size={25} />
          </Link> */}
        </div>
      </td>
    </tr>
  )
}
