import React, { useEffect, useMemo, useState } from "react";
import LoaderTableau from "../../tableauComponent/LoaderTableau";
import { trierTableau } from "../../../utils/Functions/tableFilter";
import TableRowComponent, {
  ClientTableRow,
} from "../../rowComponent/TableRowComponent";
import { useAppContext } from "../../../context";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";

export default function ExpeditionLists({
  type_expedition,
  items,
  setItems,
  type,
  isLoading,
}) {
  const { isAuth, setIsAuth } = useAppContext();
  const entete =
    isAuth?.spaceName === "medical"
      ? [
          "REF",
          "EXP",
          "REF interne",
          "Code don",
          "adresse",
          "DEST",
          "Code rec",
          "adresse",
          "TRACKING",
          "Action",
        ]
      : ["REF", "EXP", "adresse", "DEST", "adresse", "TRACKING", "Action"];

  const [filterBy, setfilterBy] = useState("ref");
  const [filterOrder, setfilterOrder] = useState("desc");

  const checkCountry = (a, b) => {
    // A expedition
    // Receiver
    if (a?.toUpperCase() === "FR" || a?.toUpperCase() === "FRANCE") {
      return "export";
    } else if (a?.toUpperCase() !== "FR" || a?.toUpperCase() !== "FRANCE") {
      return "import";
    }
  };

  const modifiedText = (text) => {
    return text
      ? text.split(" ").map((word, index) => {
          return index !== 0 && index % 3 === 0
            ? [<br key={index} />, word]
            : word + " ";
        })
      : "-";
  };

  const tableDataFilter = (index) => {
    //console.log('filte ',filterKey(index),filterOrder === "desc" ? "asc" : "desc")
    setfilterBy(filterKey(index));
    setfilterOrder(filterOrder === "desc" ? "asc" : "desc");
  };

  const filterKey = (index) => {
    if (isAuth?.spaceName !== "medical") {
      if (index === 0) {
        return "ref";
      } else if (index === 1) {
        return "expeditor.fullname";
      } else if (index === 2) {
        return "expeditor.address1";
      } else if (index === 3) {
        return "expeditor.country";
      } else if (index === 4) {
        return "receiver.fullname";
      } else if (index === 5) {
        return "receiver.address1";
      } else if (index === 6) {
        return "receiver.country";
      }
    } else {
      if (index === 0) {
        return "ref";
      } else if (index === 1) {
        return "expeditor.fullname";
      } else if (index === 2) {
        return "customer_ref";
      } else if (index === 3) {
        return "code_donneur";
      } else if (index === 4) {
        return "expeditor.address1";
      } else if (index === 5) {
        return "expeditor.country";
      } else if (index === 6) {
        return "receiver.fullname";
      } else if (index === 7) {
        return "code_receveur";
      } else if (index === 8) {
        return "receiver.address1";
      } else if (index === 9) {
        return "receiver.country";
      }
    }
  };

  let filtedArray = useMemo(
    () => trierTableau(items, filterBy, filterOrder),
    [filterBy, items, filterOrder]
  );

  /*   useEffect(() => {
      let filtedArray = trierTableau(items, filterBy, filterOrder);
      console.log('filtered list ', filtedArray)
      setItems([...filtedArray])
    }, [filterOrder, filterBy, items]) */

  //console.log('__', filtedArray.map(i => i.ref))


  return (
    <div className="mb-5 overflow-auto ">
      <div className="inline-block min-w-full py-2 align-middle ">
        <table className="w-full divide-y divide-gray-300">
          <thead className={"bg-[#ECECEC]"}>
            <tr className={" text-black "}>
              {entete.map((item, index) => (
                <th
                  onClick={() => tableDataFilter(index)}
                  key={item}
                  scope="col"
                  className="py-3.5 pl-4 pr-3  text-base font-normalsm:pl-0 text-center hover:cursor-pointer "
                >
                  <div className={"flex items-center gap-1"}>
                    {" "}
                    {item.toUpperCase()}
                    {filterOrder === "asc" ? (
                      <ArrowUpIcon
                        className={clsx(
                          {
                            "text-paris": isAuth?.spaceName === "paris",
                            "text-medical": isAuth?.spaceName === "medical",
                            "text-service": isAuth?.spaceName === "service",
                            "text-admin": isAuth?.spaceName === "admin",
                          },
                          "w-3 h-3"
                        )}
                      />
                    ) : (
                      <ArrowDownIcon
                        className={clsx(
                          {
                            "text-paris": isAuth?.spaceName === "paris",
                            "text-medical": isAuth?.spaceName === "medical",
                            "text-service": isAuth?.spaceName === "service",
                            "text-admin": isAuth?.spaceName === "admin",
                          },
                          "w-3 h-3"
                        )}
                      />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {isLoading ? (
              <>
                <tr>
                  <td colSpan={entete.length}>
                    <LoaderTableau />
                  </td>
                </tr>
              </>
            ) : (
              <>
                {filtedArray.length > 0 ? (
                  <>
                    {filtedArray.map((item, index) => {
                      return <ClientTableRow key={index} item={item} />;
                    })}
                  </>
                ) : (
                  <tr>
                    <td colSpan={entete.length}>
                      <div classname={"py-4 rounded-lg bg-white "}>
                        <p
                          className={
                            "my-4 text-xl font-medium text-center text-black "
                          }
                        >
                          Pas de données actuellement !{" "}
                        </p>
                      </div>
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}