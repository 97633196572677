import React, { useEffect, useState } from "react";
import Lists from "./Lists";
import AttributionFormModal from "./Form";
import { toast } from "react-toastify";
import useDisclosure from "../../../hooks/useDisclosure";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import DeletionConfirm from "./DeletionConfirm";
import Filter from "./Filter";
import ExpeditionService from "../../../services/expedition/ExpeditionService";
import opsService from "../../../services/ops/opsService";
import DispatchFormModal from "./DispatchFormModal";
import { AddTrackingModal } from "../../tracking/Form";
import { useDispatch, useSelector } from "react-redux";
import {
  addExpedition,
  clearExpeditions,
  initExpedition,
} from "../../../features/slices/expeditionSlice";
import HeaderComponent from "../../header/header.component.jsx";
import { exnetSpaceColor } from "../../../utils/Functions/otherFunctions";
import useFacturations from "../../../states/facturations";
import PaginationComponent from "../../paginationComponent/PaginationComponent.jsx";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useAppContext } from "../../../context/index.jsx";

export const ExpeditionsView = ({
  category,
  type_expedition,
  route,
  title,
  subtitle,
}) => {
  const expeditionStore = useSelector(
    (state) => state.expedition.allExpedition
  );
  const dispatch = useDispatch();
  const { isAuth, setIsAuth } = useAppContext();

  const [isSearch, setisSearch] = useState(false);
  const [filterData, setfilterData] = useState({});
  const { pathname } = useLocation();
  const splitPathname = pathname.split("/");
  const [titlePage, settitlePage] = useState("");
  const [subtitlePage, setsubtitlePage] = useState("");
  const [coursiers, setCoursiers] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedItem, setSelectedItem] = useState();
  const [editMode, setEditMode] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [perPageItems, setperPageItems] = useState(25);

  useEffect(() => {
    settitlePage(title);
    setsubtitlePage(subtitle);
  }, [title, subtitle]);

  const {
    isOpen: isAttributionOpen,
    onClose: onAttributionClose,
    onOpen: onAttributionOpen,
  } = useDisclosure();
  const {
    isOpen: isDeleteConfirmOpen,
    onClose: onDeleteConfirmClose,
    onOpen: onDeleteConfirmOpen,
  } = useDisclosure();

  const {
    isOpen: isDispatchModalOpen,
    onClose: onDispatchModalClose,
    onOpen: onDispatchModalOpen,
  } = useDisclosure();

  const {
    isOpen: isAddTrackingModalOpen,
    onClose: onAddTrackingModalClose,
    onOpen: onAddTrackingModalOpen,
  } = useDisclosure();

  const { milieu: type } = useParams();
  const [data, setData] = useState({
    data: [],
  });

  const [isLoading, setIsLoading] = useState(true);
  const [attributionProfile, setAttributionProfile] = useState();
  const [filter, setFilter] = useState({
    ref: "",
    suivi: "",
    contact: "",
    billing_center: "",
    delivery_date: "",
    pickup_date: "",
    code_donneur: "",
    code_receveur: "",
  });

  const onSubmit = (result) => {
    let items = [...data.data];
    const resultIndex = items.findIndex((i) => i.id === result?.id);
    const isUpdate = resultIndex > -1;
    if (isUpdate) {
      items[resultIndex] = result;
    } else {
      items = [result, ...items];
    }

    setData((d) => ({
      ...d,
      data: items,
    }));

    onAddTrackingModalClose();

    toast.success(
      `${isUpdate ? "Détails mis à jour" : "Données ajoutées"} avec succès!`,
      {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const onItemSelected = (item, isEditable) => {
    if (isEditable) {
      setEditMode(true);
    }
    setSelectedItem(item);
    onAttributionOpen();
  };

  const handleClose = () => {
    setSelectedItem(null);
    onAttributionClose();
    onDispatchModalClose();
    setEditMode(false);
  };

  const handleDeleteModalClose = () => {
    onDeleteConfirmClose();
  };

  const onDelete = (item) => {
    setSelectedItem(item);
    onDeleteConfirmOpen();
  };

  const onDeleted = (item) => {
    const items = [...data.data];
    const index = items.findIndex((i) => i.id === item?.id);
    if (index > -1) {
      items.splice(index, 1);
      setData((d) => ({
        ...d,
        data: items,
      }));
    }
    onDeleteConfirmClose();
    setSelectedItem(undefined);
  };

  const results = data.data.filter((d) => {
    const {
      ref,
      suivi,
      contact,
      billing_center,
      delivery_date,
      pickup_date,
      code_donneur,
      code_receveur,
    } = filter;
    // alert(d.ref)

    const refMatch = d?.ref
      ?.toString()
      .toLowerCase()
      .includes(ref.toString().toLowerCase());

    const codeDonneurMatch =
      code_donneur.length > 0
        ? d?.code_donneur
            ?.toLowerCase()
            .includes(code_donneur?.toLowerCase() || null)
        : true;

    const codeReceveursMatch =
      code_receveur.length > 0
        ? d?.code_receveur
            ?.toLowerCase()
            .includes(code_receveur?.toLowerCase() || null)
        : true;

    return codeDonneurMatch && codeReceveursMatch && refMatch;
  });

  const onAttributionSelected = (item, profile) => {
    setSelectedItem(item);
    setAttributionProfile(profile);
    onAttributionOpen();
  };

  const onDispatchModalSelected = (item) => {
    setSelectedItem(item);
    onDispatchModalOpen();
  };

  const onAddTrackingSelected = (item) => {
    setSelectedItem(item);
    onAddTrackingModalOpen();
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const params = {
        page: page,
        type:isAuth?.spaceName,
        category,
        type_expedition,
        per_page: parseInt(perPageItems),
      };

      let newArray = Object.keys(filterData).length > 0;
      if (Object.keys(filterData).length > 0) {
        params.filter = filterData;
      }

      console.log(params);
      const { data: resData } = await ExpeditionService.getAllExpedition(
        route,
        params
      );

      if (resData.success) {
        const responseData = resData.data.data;
        setTotalItems(resData.data.total);
        setData({ data: [...responseData] });
        dispatch(initExpedition(responseData));
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const resetAllStates = () => {
    setisSearch(false);
    setfilterData({});
    settitlePage("");
    setsubtitlePage("");
    setCoursiers([]);
    setData({ data: [] });
    setIsLoading(true);
    setAttributionProfile();
    dispatch(initExpedition([]));
    setFilter({
      ref: "",
      suivi: "",
      contact: "",
      billing_center: "",
      delivery_date: "",
      pickup_date: "",
      code_donneur: "",
      code_receveur: "",
    });
    setPage(1);
    setSelectedItem();
    setEditMode(false);
  };

  const navigate = useNavigate();
  const { setFacturationLines, setSelectedRows } = useFacturations();

  useEffect(() => {
    setFacturationLines([]);
    setSelectedRows([]);
    fetchData();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    fetchData();
    const fetchCoursiers = async () => {
      const isChauffeur = type === "chauffeurs";
      const request = isChauffeur
        ? opsService.getChauffeurs
        : opsService.getCoursiers;
      const { data } = await request();
      if (data.success) {
        setCoursiers(
          data.data.map((c) => {
            const value = c[isChauffeur ? "chaufeur" : "coursier"];
            return { label: value, value };
          })
        );
      }
    };

    fetchCoursiers();
  }, [page, perPageItems, pathname]);

  const handleSearch = () => {
    dispatch(initExpedition([]));
    setIsLoading(true);
    setisSearch(true);
    setPage(1);
    setData({ data: [] }); // Réinitialisez les données
    fetchData({ filterData: filterData, laPage: 1 });
  };

  const revokeSearch = () => {
    dispatch(initExpedition([]));
    setfilterData({});
    setIsLoading(true);
    setisSearch(false);
    setPage(1);
    setData({ data: [] }); // Réinitialisez les données
    fetchData({ filterData: null, laPage: 1 });
  };

  const onAttributionDone = (result) => {
    let items = [...data.data];
    const resultIndex = items.findIndex((i) => i.id === result?.id);
    const isUpdate = resultIndex > -1;
    if (isUpdate) {
      console.log("result", result);
      items[resultIndex] = {
        ...items[resultIndex],
        driver_id: result?.driver,
        recupererops: result?.recupererops,
        remisops: result?.recupererops,
      };
    } else {
      items = [result, ...items];
    }

    setData((d) => ({
      ...d,
      data: items,
    }));

    onAddTrackingModalClose();

    toast.success(
      `${isUpdate ? "Détails mis à jour" : "Données ajoutées"} avec succès!`,
      {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const handlePageClick = (event) => {
    console.log(event);
    setPage(event);
    setCurrentPage(event);
  };

  const isUniqueBillingCenter = results.every(
    (item) => item.billing_center_id === filterData.billing_center
  );

  return (
    <div className={"px-2 md:px-0"}>
      <div className="flex items-center justify-between">
        <HeaderComponent title={titlePage} subtitle={subtitlePage} />

        {![
          "en-cours",
          "livre",
          "a-archivee",
          "facturee",
          "annulee",
          "en-attente",
        ].includes(splitPathname[4]) &&
          splitPathname[4] && (
            <Link
              to={`/${splitPathname[1]}/${splitPathname[2]}/dash/${
                splitPathname[4]
                  ? splitPathname[4]
                  : "en-cours".toLowerCase().replace(/ /g, "-")
              }/new`}
              className="float-right btn btn-rounded"
              style={{
                color: "#fff",
                backgroundColor: `${exnetSpaceColor(isAuth?.spaceName)}`,
              }}
            >
              <PlusIcon  className={"h-4 w-4 m-3 text-xl font-bold "}/>
            </Link>
          )}
      </div>
      <Filter
        setfilterData={setfilterData}
        filterData={filterData}
        type={isAuth?.spaceName}
        revokeSearch={revokeSearch}
        handleSearch={handleSearch}
      />
      <div>
        <PaginationComponent
          page={page}
          handlePageClick={handlePageClick}
          totalItems={totalItems}
          perPageItems={perPageItems}
          setnumberPerPage={setperPageItems}
        />
      </div>
      <Lists
        coursiersList={coursiers}
        items={results}
        onSelect={onItemSelected}
        onAttributionSelect={onAttributionSelected}
        onDelete={onDelete}
        onDispatchModalSelect={onDispatchModalSelected}
        onAddTrackingClick={onAddTrackingSelected}
        type={isAuth?.spaceName}
        pathname={splitPathname}
        isLoading={isLoading}
        checkboxVisible={
          splitPathname[4] === "facturee" || splitPathname[5] === "facturee"
        }
        allowCheckBoxToggler={isUniqueBillingCenter}
      />
      <div>
        <PaginationComponent
          page={page}
          element={"pagination"}
          handlePageClick={handlePageClick}
          totalItems={totalItems}
          perPageItems={perPageItems}
          setnumberPerPage={setperPageItems}
        />
      </div>
      <AttributionFormModal
        onSubmit={onAttributionDone}
        selectedData={selectedItem}
        isOpen={isAttributionOpen}
        onClose={handleClose}
        editable={editMode}
        type={isAuth?.spaceName}
        selectedProfile={attributionProfile}
      />

      <DispatchFormModal
        selectedData={selectedItem}
        isOpen={isDispatchModalOpen}
        onClose={handleClose}
        type={isAuth?.spaceName}
      />

      <DeletionConfirm
        onSubmit={onDeleted}
        selectedData={selectedItem}
        isOpen={isDeleteConfirmOpen}
        onClose={handleDeleteModalClose}
        title={"Etes-vous sûr de vouloir supprimer ce manifest?"}
        type={isAuth?.spaceName}
      />
      <AddTrackingModal
        expedition={selectedItem}
        isOpen={isAddTrackingModalOpen}
        onClose={onAddTrackingModalClose}
        onSubmit={onSubmit}
        type={isAuth?.spaceName}
        showComment={true}
      />
    </div>
  );
};
