import React from 'react';
import LoaderTableau from '../../tableauComponent/LoaderTableau';
import { TableRow } from './Row';

import useTableFilter from '../../../hooks/useTableFilter';
import { TbArrowsUpDown } from 'react-icons/tb';

function List({ isLoading, items }) {

    const { results, onTableHeaderClick } = useTableFilter({ items });

    const HEADERS = [
        { label: 'N°', key: 'id' },
        { label: 'TEMPLATE', key: 'details' },
        { label: 'PARIS', key: 'isparis' },
        { label: 'MEDICALS', key: 'ismedical' },
        { label: 'SERVICES', key: 'isservice' },
        { label: 'CHAUFFEUR', key: 'isdriver' },
        { label: 'DATE DE CREATION', key: 'created_at' },
        // Add more columns as needed
        // { label: 'ACTION', key: 'action' },
    ];

    return (
        <div>


            <div className="p-6 mx-auto bg-white rounded-lg shadow ">
                <table className="min-w-full border-collapse">
                    <thead>
                        <tr>
                            {HEADERS.map((h, i) => (
                                <th key={i} className="text-left cursor-pointer" onClick={() => {
                                    onTableHeaderClick(h.key)
                                }}>
                                    <div className="flex items-center">
                                        <span className='mr-2'>
                                            {h.label}
                                        </span>
                                        {h.key && <span>
                                            <TbArrowsUpDown />
                                        </span>}
                                    </div>

                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {!isLoading ? (
                            <>
                                {results.length === 0 ? (
                                    <tr key="">
                                        <td colSpan={HEADERS.length}>Il n'y a pas de template pour le moment</td>
                                    </tr>
                                ) : (<>
                                    {results.map((item, index) => (
                                        <TableRow key={index} item={item} />
                                    ))
                                    }

                                </>)}
                            </>

                        ) : (
                            <tr key="">
                                <td colSpan={HEADERS.length}>
                                    <LoaderTableau />
                                </td>
                            </tr>
                        )}

                        {/* Vous pouvez ajouter d'autres lignes de la même manière */}
                    </tbody>
                </table>
            </div>

        </div>
    );
}

export default List;