
import medicalImport from '../../../assets/img/svg/Medicals-import.svg'
import medicalExport from '../../../assets/img/svg/Medicals-export.svg'
import dashLogo from '../../../assets/img/Icones/Dashboard/medicals.svg'
import contactLogo from '../../../assets/img/Icones/carnet/medicals.svg'
import archiveLogo from '../../../assets/img/Icones/archive/medicals.svg'
import boiteLogo from '../../../assets/img/Icones/boite/medicals.svg'
import freightLogo from '../../../assets/img/Icones/freight/medicals.svg'


export const medicalClientMenu = [
    {
        libelle: "Dashboard",
        // lien: "/client/medical/dash",
        icon: <img alt={'fd'} src={dashLogo} className={`mt-0 w-8 hover:text-black`} />,
        liens: [
            {
                libelle: "Expéditions en cours",
                lien: "/client/medical/dash",
                tague: 'dashboard-progress'
            },
            {
                libelle: "Expéditions livrées",
                lien: "/client/medical/dash/livre",
                tague: 'dashboard-delivered'
            },
        ],
        isTitle: false,
        tague: "dashboard",
        isMultiple: true,
    },
    {
        libelle: "Mes commandes",
        isTitle: true
    },

    {
        isMultiple: true,
        tague: "export",
        isTitle: false,
        libelle: "Exports",
        icon: <img alt={'fd'} src={medicalExport} className={`mt-0 w-8 hover:text-black`} />,
        liens: [
            {
                libelle: "Nouvel export",
                lien: "/client/medical/dash/export/new",
                tague: "export-new",
            },
            {
                libelle: "Exports en cours",
                lien: "/client/medical/dash/export/en-cours",
                tague: "export-progress",
            },
            {
                libelle: "Exports livrées",
                lien: "/client/medical/dash/export/livre",
                tague: "export-delivered",
            },

        ],
    },
    {
        isMultiple: true,
        tague: "import",
        isTitle: false,
        libelle: "Imports",
        icon: <img alt={'fd'} src={medicalImport} className={`mt-0 w-8 hover:text-black`} />,
        liens: [

            {
                libelle: "Nouvel import",
                lien: "/client/medical/dash/import/new",
                tague: "import-new",
            },
            {
                libelle: "Imports en cours",
                lien: "/client/medical/dash/import/en-cours",
                tague: "import-progress",
            },
            {
                libelle: "Imports livrées",
                lien: "/client/medical/dash/import/livre",
                tague: "import-delivered",
            }
        ],
    },
    {
        libelle: "Carnet d'adresses",
        lien: "/client/medical/dash/carnet",
        icon: <img alt={'fd'} src={contactLogo} className={` w-8 hover:text-black`} />,
        tague: 'contact'
    },
    {
        libelle: "Expéditions archivées",
        lien: "/client/medical/dash/export/archive",
        icon: <img alt={'fd'} src={archiveLogo} className={` w-8 hover:text-black`} />,
        tague: 'export-archived'
    },

    {
        libelle: "Mes Statistiques",
        isTitle: true
    },
    {
        libelle: "Courses",
        lien: "/client/medical/dash/statiques/expeditions",
        icon: <img alt={'fd'} src={boiteLogo} className={` w-8 hover:text-black`} />,
        tague: 'statistic-expedition'
    },
    {
        libelle: "Freight",
        lien: "/client/medical/dash/statiques/freight",
        icon: <img alt={'fd'} src={freightLogo} className={` w-8 hover:text-black`} />,
        tague: 'statistic-freight'
    }
];
